// src/Pages/Vehicles/Models.jsx

import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { MdEdit, MdDelete } from "react-icons/md";
import Pagination from "../../Components/Pagination";
import vehicleAPI from "../../utils/API/vehicleApis";
import { useNavigate } from "react-router-dom";
import useAuthToken from "../../hooks/customHooks";
import { toast } from "sonner"; // Import Sonner for toast notifications
import ConfirmModal from "../../Components/ConfirmModal"; // Import ConfirmModal

function ModelsList() {
  const navigate = useNavigate();
  const token = useAuthToken();

  // Updated filterValue and searchPayload to use 'searchString' instead of 'modelName'
  const [filterValue, setFilterValue] = useState({
    searchString: "",
  });
  const [searchPayload, setSearchPayload] = useState({
    searchString: "",
  });

  // State for pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // State for models data, total pages, and total items
  const [models, setModels] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0); // Added totalItems

  // State for loading and error
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // State for confirmation modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelToDelete, setModelToDelete] = useState(null);

  /**
   * Fetches models from the API based on current filters and pagination.
   */
  const fetchModels = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await vehicleAPI.getModelsAPI(
        currentPage,
        itemsPerPage,
        searchPayload,
        token
      );

      setModels(response.data.items);
      setTotalPages(response.data.totalPages);
      setTotalItems(response.data.totalItems); 
    } catch (err) {
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "An error occurred while fetching models.";
      setError(errorMessage);
      toast.error(errorMessage); 
    } finally {
      setLoading(false);
    }
  };

  /**
   * Fetch data whenever currentPage, itemsPerPage, or searchPayload changes
   */
  useEffect(() => {
    fetchModels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, searchPayload]);

  /**
   * Handles the filter button click.
   */
  const handleFilter = () => {
    setSearchPayload(filterValue);
    setCurrentPage(1); // Reset to first page on new filter
  };

  /**
   * Handles changes in the search input.
   */
  const handleSearchChange = (e) => {
    setFilterValue((prev) => ({
      ...prev,
      searchString: e.target.value,
    }));
  };

  /**
   * Resets the filters to their initial state.
   */
  const handleReset = () => {
    setFilterValue({ searchString: "" });
    setSearchPayload({ searchString: "" });
    setCurrentPage(1);
  };

  /**
   * Navigates to the edit model page.
   * @param {string} modelId - The ID of the model to edit.
   * @param {object} model - The model object.
   */
  const handleEdit = (modelId, model) => {
    navigate(`/vehicles/edit-model/${modelId}`, { state: { model } });
  };

  /**
   * Opens the confirmation modal to delete a model.
   * @param {object} model - The model object to delete.
   */
  const handleDeleteClick = (model) => {
    setModelToDelete(model);
    setIsModalOpen(true);
  };

  /**
   * Confirms the deletion of the model.
   */
  const confirmDeleteModel = async () => {
    if (!modelToDelete) return;

    setIsModalOpen(false);
    setLoading(true);
    setError(null);

    try {
      let response = await vehicleAPI.deleteModelAPI(
        modelToDelete.id,
        currentPage,
        itemsPerPage,
        token
      );
      if (response.status === 200) {
        toast.success("Model deleted successfully!");
      } else {
        toast.error(response.data.message||"Model couldn't be deleted");
      }

      // Refetch models to update the list
      fetchModels();
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || err.message || "Failed to delete model.";
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
      setModelToDelete(null);
    }
  };

  /**
   * Cancels the deletion and closes the modal.
   */
  const cancelDelete = () => {
    setIsModalOpen(false);
    setModelToDelete(null);
  };

  /**
   * Handles change in items per page.
   * @param {object} e - Event object.
   */
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  /**
   * Handles page number click.
   * @param {number} pageNumber - The page number to navigate to.
   */
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  /**
   * Handles navigation to the first page.
   */
  const handleFirstPage = () => setCurrentPage(1);

  /**
   * Handles navigation to the last page.
   */
  const handleLastPage = () => setCurrentPage(totalPages);

  /**
   * Handles navigation to the previous page.
   */
  const handlePreviousPage = () =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));

  /**
   * Handles navigation to the next page.
   */
  const handleNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        {/* Sidebar with current selection */}
        <Sidebar currentSelection={{ main: "vehicles", sub: "model" }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto h-screen">
          <div className="container mx-auto p-4">
            {/* Heading */}
            <h1 className="text-2xl text-gray-600 font-bold mb-4">
              Models
            </h1>

            {/* Search and Filter Section */}
            <div className="bg-customBlue p-5 mb-8 rounded-xl">
              <div className="flex space-x-16 items-center">
                {/* Search Input */}
                <div className="flex flex-col text-white">
                  <label htmlFor="search">Search</label>
                  <input
                    id="search"
                    type="text"
                    placeholder="Search by Model Name"
                    value={filterValue.searchString} // Updated value binding
                    onChange={handleSearchChange}
                    className="border border-gray-300 rounded p-2 w-96"
                  />
                </div>
              </div>

              {/* Filter and Reset Buttons */}
              <div className="flex gap-2 mt-4">
                <button
                  className="bg-green-700 text-white border border-green-700 rounded-lg p-2 h-10"
                  onClick={handleFilter}
                >
                  Filter
                </button>
                <button
                  className="bg-red-700 text-white border border-red-700 rounded-lg p-2 h-10"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </div>

            {/* Models List Header */}
            <div className="flex bg-stone-50 font-semibold justify-between text-gray-600 mb-0.5 p-3 rounded-t-xl">
              <div>Models List</div>
              {/* Items Per Page Selector */}
              <div className="flex items-center space-x-2">
                <label htmlFor="itemsPerPage" className="text-sm">
                  Items per page:
                </label>
                <select
                  id="itemsPerPage"
                  className="border border-gray-300 text-sm rounded p-2"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
                <button
                  className="border border-emerald-900 text-sm font-normal rounded-md p-2 text-emerald-900"
                  onClick={() => {
                    navigate("/vehicles/add-model");
                  }}
                >
                  + Add New Model
                </button>
              </div>
            </div>

            {/* Pagination and Items Information */}
            <div className="flex flex-col md:flex-row bg-stone-50 justify-between items-center text-sm mb-0.5 p-3 rounded-b-none rounded-t-none">
              {/* Showing X to Y of Z */}
              <div className="mb-2 md:mb-0">
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span>
                    Showing{" "}
                    {totalItems === 0
                      ? 0
                      : (currentPage - 1) * itemsPerPage + 1}{" "}
                    to {Math.min(currentPage * itemsPerPage, totalItems)} of{" "}
                    {totalItems}
                  </span>
                )}
              </div>

              {/* Pagination Component */}
              {totalPages > 1 && (
                <div className="flex justify-center mt-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageClick}
                    onFirstPage={handleFirstPage}
                    onLastPage={handleLastPage}
                    onPreviousPage={handlePreviousPage}
                    onNextPage={handleNextPage}
                  />
                </div>
              )}
            </div>

            {/* Models List Section */}
            <div className="space-y-0">
              {/* Header Row */}
              <div className="flex bg-stone-50 justify-center items-center font-bold text-sm mb-0.5 p-2 border-b border-gray-300">
                <div className="flex-1 text-left">ID</div>
                <div className="flex-1 text-left">Model</div>
                <div className="flex-1 text-left">Brand</div>
                <div className="flex-1 text-left">Created At</div>
                <div className="flex-1 text-right">Actions</div>
              </div>

              {/* Loading and Error States */}
              {loading ? (
                <div className="flex justify-center p-4 text-gray-500">
                  Loading...
                </div>
              ) : error ? (
                <div className="flex bg-white p-4 text-center text-gray-500">
                  Failed to load models.
                </div>
              ) : models?.length > 0 ? (
                models.map((model, index) => (
                  <div
                    key={model.id} // Ensure model.id is unique and exists
                    className="flex bg-white p-2 border-b border-gray-300 transition-transform transform text-sm gap-8 hover:bg-gray-50"
                  >
                    <div className="flex-1 px-1 text-left">
                      {(currentPage - 1) * itemsPerPage + index + 1}
                    </div>
                    <div
                      className="flex-1 overflow-hidden text-left"
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}
                    >
                      {model.modelName} ({model.year})
                    </div>
                    <div className="flex-1 text-left">{model.brandName}</div>
                    <div className="flex-1 mr-4 text-left">
                      {new Date(model.createdAt).toLocaleDateString()}
                    </div>

                    <div className="flex-1 flex justify-end space-x-2">
                      <button
                        className="bg-transparent text-green-700 border border-green-700 rounded p-2 text-xs h-8 hover:bg-green-100"
                        aria-label={`Edit model ${model.id}`}
                        onClick={() => handleEdit(model.id, model)}
                      >
                        <MdEdit />
                      </button>
                      <button
                        className="bg-transparent text-red-700 border border-red-700 rounded p-2 text-xs h-8 hover:bg-red-100"
                        aria-label={`Delete model ${model.id}`}
                        onClick={() => handleDeleteClick(model)}
                        disabled={loading} // Disable while loading
                      >
                        <MdDelete />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex bg-white p-4 text-center text-gray-500">
                  No models found.
                </div>
              )}

              {/* Footer Showing X to Y of Z */}
              <div className="flex flex-col md:flex-row bg-stone-50 justify-between items-center text-sm mb-0.5 p-3 rounded-b-xl">
                <div>
                  Showing{" "}
                  {totalItems === 0 ? 0 : (currentPage - 1) * itemsPerPage + 1}{" "}
                  to {Math.min(currentPage * itemsPerPage, totalItems)} of{" "}
                  {totalItems}
                </div>

                {/* Pagination Component at Bottom (Optional) */}
                {totalPages > 1 && (
                  <div className="flex justify-center mt-4">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageClick}
                      onFirstPage={handleFirstPage}
                      onLastPage={handleLastPage}
                      onPreviousPage={handlePreviousPage}
                      onNextPage={handleNextPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Modal for Deletion */}
      <ConfirmModal
        isOpen={isModalOpen}
        title="Confirm Deletion"
        message={`Are you sure you want to delete the model "${modelToDelete?.modelName}"? This action cannot be undone.`}
        onConfirm={confirmDeleteModel}
        onCancel={cancelDelete}
      />

      {/* Remove the fixed error display and rely on toasts */}
      {/* {error && (
        <div className="fixed bottom-4 right-4 bg-red-500 text-white p-3 rounded">
          {error}
        </div>
      )} */}
    </>
  );
}

export default ModelsList;
