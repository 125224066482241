// src/Pages/Coupons/AllCoupons.jsx
import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import couponsApi from "../../utils/API/couponsApi"; // Import your coupons API

function AllCoupons() {
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [coupons, setCoupons] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Fetch Coupons from the server
   * @param {number} page
   * @param {number} limit
   */
  const fetchCoupons = async (page, limit) => {
    setLoading(true);
    setError(null);

    try {
      // Get token however your app does (e.g., localStorage)
      const token = localStorage.getItem("authToken");
      if (!token) throw new Error("Token not found. Please log in.");

      // Call our coupon API function
      const response = await couponsApi.getCoupons(page, limit, token);

      // response.data is { data: [...coupons], totalItems, totalPages, currentPage }
      setCoupons(response.data.data);
      setTotalItems(response.data.totalItems);

      // If totalPages is 0 but data exists, do Math.max(1, response.data.totalPages)
      setTotalPages(Math.max(1, response.data.totalPages));
    } catch (err) {
      console.error("Fetch Coupons Error:", err.message);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch coupons whenever currentPage or itemsPerPage changes
  useEffect(() => {
    fetchCoupons(currentPage, itemsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage]);

  // Handle items per page changes
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page on change
  };

  // Handle simple previous/next page
  const handlePageChange = (direction) => {
    if (direction === "next" && currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        {/* Highlight the active sidebar item */}
        <Sidebar currentSelection={{ main: "coupons", sub: "allCoupons" }} />

        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto">
          <div className="container mx-auto p-4">
            <h1 className="text-2xl text-gray-600 font-bold mb-4">All Coupons</h1>

            {/* (Optional) Search & Filter Section */}
            <div className="bg-customBlue p-5 mb-8 rounded-xl">
              <div className="flex space-x-16 items-center">
                <div className="flex flex-col text-white">
                  <label>Search</label>
                  <input
                    type="text"
                    placeholder="Search by Name | Code"
                    className="border border-gray-300 rounded p-2 w-96"
                  />
                </div>
                <div className="flex flex-col text-gray-600">
                  <label>Status</label>
                  <select className="border border-gray-300 rounded p-2">
                    <option>-- Select --</option>
                    <option>Active</option>
                    <option>Expired</option>
                    <option>Inactive</option>
                  </select>
                </div>
              </div>
              <div className="flex gap-2 mt-4">
                <button className="bg-green-700 text-white border border-green-700 rounded-lg p-2 h-10">
                  Filter
                </button>
                <button className="bg-red-700 text-white border border-red-700 rounded-lg p-2 h-10">
                  Reset
                </button>
              </div>
            </div>

            {/* Main Content: Coupons Table */}
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) : (
              <>
                {/* Header Row & Pagination Controls */}
                <div className="flex bg-stone-50 font-semibold text-gray-600 mb-0.5 p-3 rounded-t-xl">
                  Coupons List
                </div>
                <div className="flex bg-stone-50 justify-between text-sm mb-0.5 p-3">
                  <span>
                    Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
                    {Math.min(currentPage * itemsPerPage, totalItems)} of {totalItems}
                  </span>
                  <div className="flex space-x-2">
                    <label htmlFor="itemsPerPage" className="mr-2">
                      Items per page:
                    </label>
                    <select
                      id="itemsPerPage"
                      className="border border-gray-300 rounded p-2"
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                    </select>
                    <div>
                      <button
                        onClick={() => handlePageChange("prev")}
                        className="bg-gray-300 text-black rounded p-2 mr-1"
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <button
                        onClick={() => handlePageChange("next")}
                        className="bg-gray-300 text-black rounded p-2"
                        disabled={currentPage === totalPages}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>

                {/* Coupons Listing */}
                <div className="space-y-0">
                  {/* Table Header */}
                  <div className="flex flex-1 bg-stone-50 justify-center items-center font-bold mb-0.5 p-2">
                    <div className="flex-1">ID</div>
                    <div className="flex-1">Offer Code</div>
                    <div className="flex-1">Discount Type</div>
                    <div className="flex-1">Discount Value</div>
                    <div className="flex-1">Valid From</div>
                    <div className="flex-1">Valid To</div>
                    <div className="flex-1">Usage Limit</div>
                    <div className="flex-1">Used Count</div>
                    <div className="flex-1"></div>
                  </div>

                  {/* Dynamically List Each Coupon */}
                  {coupons.map((coupon) => (
                    <div
                      key={coupon.id}
                      className="flex bg-stone-50 p-2 border-b border-gray-300 transition-transform transform text-sm gap-8 hover:bg-gray-50"
                    >
                      <div className="flex-1">{coupon.id}</div>
                      <div className="flex-1">{coupon.offerCode}</div>
                      <div className="flex-1">{coupon.discountType}</div>
                      <div className="flex-1">{coupon.discountValue}</div>
                      <div className="flex-1">
                        {new Date(coupon.validFrom).toLocaleDateString()}
                      </div>
                      <div className="flex-1">
                        {new Date(coupon.validTo).toLocaleDateString()}
                      </div>
                      <div className="flex-1">{coupon.usageLimit}</div>
                      <div className="flex-1">{coupon.usedCount}</div>
                      <div className="flex-1 flex justify-end">
                        <button
                          className="bg-transparent text-green-700 border border-green-700 rounded p-1 text-xs h-8 mr-1"
                          onClick={() => {
                            // e.g. navigate(`/edit-coupon/${coupon.id}`);
                          }}
                        >
                          Edit
                        </button>
                        <button className="bg-transparent text-red-700 border border-red-700 rounded p-1 text-xs h-8">
                          Delete
                        </button>
                      </div>
                    </div>
                  ))}

                  {/* Footer (items displayed) */}
                  <div className="flex bg-stone-50 text-sm mb-0.5 p-3 rounded-b-xl">
                    Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
                    {Math.min(currentPage * itemsPerPage, totalItems)} of {totalItems}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AllCoupons;
