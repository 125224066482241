// src/Pages/Rides/RideBookingForm.js

import React, { useState, useEffect } from 'react';
import Navbar from '../../Components/Navbar';
import Sidebar from '../../Components/Sidebar';
import img from '../../Asset/profile.png';
import {
  GoogleMap,
  useLoadScript,
  Marker,
  DirectionsRenderer,
  Autocomplete,
} from '@react-google-maps/api';
import CustomerSearch from './CustomerSearch';
import { toast } from 'sonner';
import apiv from '../../utils/API/vehicleApis'; // For vehicle-related API functions
import api from '../../utils/API/driverApis';   // For driver-related API functions
import useAuthToken from '../../hooks/customHooks'; // Import the useAuthToken hook

const apiKey = "AIzaSyDfYVUBrPAHGZb73XFV8ttWJkRcDhlEpzc"; 


const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

const libraries = ['places'];

const RideBookingForm = () => {
  // Authentication token obtained via useAuthToken hook
  const token = useAuthToken(); // Ensure this hook returns the token correctly

  // State variables
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedDriverID, setSelectedDriverID] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedVehicleID, setSelectedVehicleID] = useState(null);
  const [vehicleModel, setVehicleModel] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [availableDrivers, setAvailableDrivers] = useState([]);
  const [loadingDrivers, setLoadingDrivers] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [distance, setDistance] = useState(null);
  const [rideRemark, setRideRemark] = useState('');
  const [error, setError] = useState(null);
  const [directions, setDirections] = useState(null);
  const [vehicleModels, setVehicleModels] = useState([]);

  // Dynamic pickup and drop locations
  const [pickupAddress, setPickupAddress] = useState('');
  const [dropAddress, setDropAddress] = useState('');
  const [pickupCoordinates, setPickupCoordinates] = useState(null);
  const [dropCoordinates, setDropCoordinates] = useState(null);

  // Autocomplete references
  const [pickupAutocomplete, setPickupAutocomplete] = useState(null);
  const [dropAutocomplete, setDropAutocomplete] = useState(null);

  // Load Google Maps scripts
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });

  // Compute directions and distance
  useEffect(() => {
    if (loadError) {
      console.error('Error loading Google Maps API:', loadError);
      toast.error('Error loading Google Maps API.');
      return;
    }

    if (
      isLoaded &&
      window.google &&
      window.google.maps &&
      pickupCoordinates &&
      dropCoordinates
    ) {
      console.log('Google Maps API is loaded. Calculating directions...');
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: pickupCoordinates,
          destination: dropCoordinates,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
            const route = result.routes[0];
            const leg = route.legs[0];
            const distanceInMeters = leg.distance.value;
            const distanceInKm = distanceInMeters / 1000;
            setDistance(parseFloat(distanceInKm).toFixed(2));
            console.log('Distance calculated:', distanceInKm.toFixed(2));
            toast.success('Directions and distance calculated successfully.');
          } else {
            console.error(`Error fetching directions: ${status}`);
            toast.error('Failed to fetch directions.');
          }
        }
      );
    } else {
      console.log('Google Maps API not loaded yet or missing data.');
    }
  }, [isLoaded, loadError, pickupCoordinates, dropCoordinates]);

  // Update selectedCustomerId when selectedCustomer changes
  useEffect(() => {
    if (selectedCustomer) {
      setSelectedCustomerId(selectedCustomer.id);
    } else {
      setSelectedCustomerId(null);
    }
  }, [selectedCustomer]);

  // Fetch vehicle models on component mount
  useEffect(() => {
    const fetchVehicleModels = async () => {
      console.log('Fetching vehicle models...');
      try {
        const page = 1;
        const items = 1000;
        const payload = {};

        console.log('Calling apiv.getModelsAPI with:', { page, items, payload, token });

        const response = await apiv.getModelsAPI(page, items, payload, token);

        console.log('Response from getModelsAPI:', response);

        if ((response.status === 200||response.status === 201) && response.data) {
          setVehicleModels(response.data.items);
          console.log('Fetched vehicle models:', response.data.items);
        } else {
          console.error('Error fetching vehicle models:', response);
          toast.error('Failed to fetch vehicle models.');
        }
      } catch (error) {
        console.error('Error fetching vehicle models:', error);
        toast.error('Error fetching vehicle models.');
      }
    };

    fetchVehicleModels();
  }, [token]);

  // Update selectedDriverID and selectedVehicleID when selectedDriver changes
  useEffect(() => {
    if (selectedDriver) {
      setSelectedDriverID(selectedDriver.driverID);
      setSelectedVehicleID(selectedDriver.vehicleId);
      setSelectedVehicle({
        vehicleId: selectedDriver.vehicleId,
        vehicleModel: selectedDriver.vehicleModel,
        vehicleType: selectedDriver.vehicleType,
        seatCapacity: selectedDriver.seatCapacity,
        driverName: selectedDriver.driverName,
      });
    } else {
      setSelectedDriverID(null);
      setSelectedVehicleID(null);
      setSelectedVehicle(null);
    }
  }, [selectedDriver]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  // Handle customer search results
  const handleCustomerSearchResults = (fetchedCustomers) => {
    setCustomers(fetchedCustomers);
  };

  // Handle customer selection
  const handleCustomerSelect = (customer) => {
    console.log('Selected Customer:', customer);
    setSelectedCustomer(customer);
    setCustomers([]); // Clear the customer list after selection
  };

  // Fetch available drivers
  const fetchAvailableDrivers = async (selectedVehicleType, selectedVehicleModel) => {
    if (!selectedVehicleType || !selectedVehicleModel) {
      toast.error('Vehicle type and model are required to find drivers.');
      return;
    }

    setLoadingDrivers(true);
    setError(null);
    setAvailableDrivers([]);

    try {
      const response = await api.adminGetDrivers(
        selectedVehicleModel,
        pickupCoordinates.lat,
        pickupCoordinates.lng,
        token // Pass the token here
      );

      console.log('Response from adminGetDrivers:', response);

      if (response.data && response.data.vehicles) {
        setAvailableDrivers(response.data.vehicles);
        if (response.data.vehicles.length > 0) {
          toast.success(`${response.data.vehicles.length} drivers found.`);
        } else {
          toast.info('No drivers found within 50 km.');
        }
      } else {
        toast.error('Unexpected response from server.');
      }
    } catch (err) {
      console.error('Error fetching drivers:', err);
      setError('Failed to fetch drivers. Please try again later.');
      toast.error('Failed to fetch drivers.');
    } finally {
      setLoadingDrivers(false);
    }
  };

  // Handle vehicle model selection
  const handleVehicleModelChange = (e) => {
    const selectedModel = e.target.value;
    setVehicleModel(selectedModel);

    // Get the selected option's vehicle type
    const selectedOption = e.target.options[e.target.selectedIndex];
    const selectedType = selectedOption.getAttribute('data-vehicletype');
    setVehicleType(selectedType);

    // **Reset Selected Driver and Vehicle**
    setSelectedDriver(null);
    setSelectedDriverID(null);
    setSelectedVehicle(null);
    setSelectedVehicleID(null);

    // Fetch available drivers whenever a vehicle model is selected
    if (pickupCoordinates) {
      fetchAvailableDrivers(selectedType, selectedModel);
    } else {
      toast.error('Please select a pickup location first.');
    }
  };

  // Handle booking submission
  const handleBookNow = async () => {
    if (!selectedCustomerId) {
      toast.error('Please select a customer.');
      return;
    }
    if (!selectedDriverID) {
      toast.error('Please select a driver.');
      return;
    }
    if (!vehicleModel || !vehicleType) {
      toast.error('Please select a vehicle model.');
      return;
    }
    if (!distance) {
      toast.error('Distance not calculated.');
      return;
    }
    if (!pickupCoordinates || !dropCoordinates) {
      toast.error('Please select both pickup and drop locations.');
      return;
    }

    // Collect all data
    const rideData = {
      customerId: selectedCustomerId,
      pickupLatitude: pickupCoordinates.lat,
      pickupLongitude: pickupCoordinates.lng,
      destinationLatitude: dropCoordinates.lat,
      destinationLongitude: dropCoordinates.lng,
      vehicleType: selectedVehicle.seatCapacity,
      // vehicleModel: vehicleModel, // Uncomment if needed
      distance: distance,
      pickup: pickupAddress,
      drop: dropAddress,
      driverID: selectedDriverID,
      rideRemark: rideRemark, // Include rideRemark if needed
    };

    console.log('Ride data being sent:', rideData);

    try {
      // Call the backend API with token
      const response = await api.adminCreateRide(rideData, token);
      console.log('Backend response:', response);

      if (response.data && (response.status === 200||response.status === 201)) {
        toast.success('Ride booked successfully.');
        // Reset form
        setSelectedCustomer(null);
        setSelectedDriver(null);
        setSelectedVehicle(null);
        setVehicleModel('');
        setVehicleType('');
        setRideRemark('');
        setAvailableDrivers([]);
        setPickupAddress('');
        setDropAddress('');
        setPickupCoordinates(null);
        setDropCoordinates(null);
        setDirections(null);
        setDistance(null);
      }
      else {
        toast.error(response.data.error || 'Failed to book ride.');
      }
    } catch (error) {
      console.error('Error booking ride:', error);

      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`Error booking ride: ${error.response.data.message}`);
      } else {
        toast.error('Error booking ride.');
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        {/* Sidebar */}
        <Sidebar currentSelection={{ main: 'rides', sub: "addRide" }} />

        {/* Main Content */}
        <div className="container mx-auto p-4 overflow-y-auto h-screen">
          <div className="flex flex-col lg:flex-row">
            {/* Form Container */}
            <div className="w-full lg:w-1/3 bg-gray-100 p-6">
              <h3 className="text-md font-light text-white bg-customBlue p-6 rounded-t-lg mb-4">
                Manage Ride Booking
              </h3>

              {/* Customer Search */}
              <CustomerSearch
                onSearchResults={handleCustomerSearchResults}
                selectedCustomer={selectedCustomer}
                setSelectedCustomer={setSelectedCustomer}
              />

              {/* Dynamic Pickup Location */}
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Pickup Location *
                </label>
                <Autocomplete
                  onPlaceChanged={() => {
                    const place = pickupAutocomplete.getPlace();
                    if (place.geometry) {
                      setPickupCoordinates({
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                      });
                      setPickupAddress(place.formatted_address || place.name);
                      // Reset Driver Selection When Pickup Location Changes
                      setSelectedDriver(null);
                      setSelectedDriverID(null);
                      setSelectedVehicle(null);
                      setSelectedVehicleID(null);
                      setAvailableDrivers([]);
                    } else {
                      toast.error('Please select a valid pickup location.');
                    }
                  }}
                  onLoad={(autocomplete) => setPickupAutocomplete(autocomplete)}
                >
                  <input
                    type="text"
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="Enter pickup location"
                    value={pickupAddress}
                    onChange={(e) => setPickupAddress(e.target.value)}
                    disabled={!selectedCustomer} // Disable if no customer is selected
                  />
                </Autocomplete>
              </div>

              {/* Dynamic Drop Location */}
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Drop Location *
                </label>
                <Autocomplete
                  onPlaceChanged={() => {
                    const place = dropAutocomplete.getPlace();
                    if (place.geometry) {
                      setDropCoordinates({
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                      });
                      setDropAddress(place.formatted_address || place.name);
                      // Reset Driver Selection When Drop Location Changes
                      setSelectedDriver(null);
                      setSelectedDriverID(null);
                      setSelectedVehicle(null);
                      setSelectedVehicleID(null);
                      setAvailableDrivers([]);
                    } else {
                      toast.error('Please select a valid drop location.');
                    }
                  }}
                  onLoad={(autocomplete) => setDropAutocomplete(autocomplete)}
                >
                  <input
                    type="text"
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="Enter drop location"
                    value={dropAddress}
                    onChange={(e) => setDropAddress(e.target.value)}
                    disabled={!selectedCustomer} // Disable if no customer is selected
                  />
                </Autocomplete>
              </div>

              {/* Vehicle Model Selection */}
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Vehicle Model *
                </label>
                <select
                  className="w-full border border-gray-300 rounded px-3 py-2"
                  value={vehicleModel}
                  onChange={handleVehicleModelChange}
                  disabled={!selectedCustomer} // Disable if no customer is selected
                >
                  <option value="">Select Vehicle Model</option>
                  {vehicleModels &&
                    vehicleModels.map((vehicle) => (
                      <option
                        key={vehicle.id}
                        value={vehicle.modelName}
                        data-vehicletype={vehicle.vehicleType}
                      >
                        {vehicle.modelName}
                      </option>
                    ))}
                </select>
              </div>
               {/* Selected Driver and Vehicle */}
               {selectedDriver && (
                <>
                  <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">
                      Selected Driver
                    </label>
                    <input
                      type="text"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      value={`${selectedDriver.driverName}`}
                      readOnly
                    />
                  </div>

                  {selectedVehicle && (
                    <div className="mb-4">
                      <label className="block text-sm font-medium mb-2">
                        Selected Vehicle
                      </label>
                      <input
                        type="text"
                        className="w-full border border-gray-300 rounded px-3 py-2"
                        value={`Model: ${selectedVehicle.vehicleModel} , Type: ${selectedVehicle.vehicleType}`}
                        readOnly
                      />
                    </div>
                  )}
                </>
              )}

              {/* Ride Remark */}
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Ride Remark
                </label>
                <textarea
                  className="w-full border border-gray-300 rounded px-3 py-2"
                  placeholder="Enter ride remark here"
                  value={rideRemark}
                  onChange={(e) => setRideRemark(e.target.value)}
                  disabled={!selectedCustomer} // Disable if no customer is selected
                />
              </div>

              {/* Book Now Button */}
              <button
                onClick={handleBookNow}
                className="w-full bg-customBlue text-white py-2 rounded hover:bg-customBlue transition duration-300"
              >
                Book Now
              </button>

              {/* Display Loading or Error Messages */}
              {loadingDrivers && <p>Loading available drivers...</p>}
              {error && <p className="text-red-500">{error}</p>}
            </div>

            {/* Right Side Content */}
            <div className="w-full lg:w-2/3 lg:pl-6 mt-6 lg:mt-0">
              {customers.length > 0 ? (
                <div className="flex flex-col space-y-4">
                  <h2 className="text-xl mb-4">Customer List</h2>
                  {customers.map((customer) => (
                    <div
                      key={customer.id}
                      className="border border-gray-300 rounded-lg p-4 shadow-md hover:shadow-lg transition duration-300 cursor-pointer"
                      onClick={() => handleCustomerSelect(customer)}
                    >
                      <div className="flex items-center">
                        <img
                          src={img}
                          alt="Customer"
                          className="w-16 h-16 rounded-full mr-4"
                        />
                        <div>
                          <p className="font-semibold text-lg">
                            {customer.firstName} {customer.lastName}
                          </p>
                          <p className="text-gray-600">Phone: {customer.phoneNumber}</p>
                          {/* Add more customer details if available */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : availableDrivers.length > 0 ? (
                // Display Available Drivers
                <div className="flex flex-col space-y-4">
                  <h2 className="text-xl mb-4">Available Drivers</h2>
                  {availableDrivers.map((driver) => (
                    <div
                      key={driver.driverID}
                      className={`border border-gray-300 rounded-lg p-4 shadow-md hover:shadow-lg transition duration-300 cursor-pointer ${selectedDriverID === driver.driverID ? 'bg-customBlue' : 'bg-white'
                        }`}
                      onClick={() => {
                        setSelectedDriver(driver);
                      }}
                    >
                      <div className="flex items-center">
                        <img
                          src={img}
                          alt="Driver"
                          className="w-16 h-16 rounded-full mr-4"
                        />
                        <div>
                          <p className="font-semibold text-lg">Driver ID: {driver.driverID}</p>
                          <p className="text-gray-600">Name: {driver.driverName}</p>
                          <p className="text-gray-600">Vehicle: {driver.vehicleModel}</p>
                          <p className="text-gray-600">Type: {driver.vehicleType}</p>
                          <p className="text-gray-600">Seats: {driver.seatCapacity}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                // Render Google Map
                <div>
                  <h2 className="text-xl mb-4">Route Information</h2>
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={pickupCoordinates || { lat: 25.2567, lng: 55.3643 }} // Default to SF if not set
                    zoom={pickupCoordinates && dropCoordinates ? 12 : 8} // Adjust zoom based on availability
                  >
                    {/* Pickup Marker */}
                    {pickupCoordinates && <Marker position={pickupCoordinates} label="P" />}

                    {/* Drop Marker */}
                    {dropCoordinates && <Marker position={dropCoordinates} label="D" />}

                    {/* Directions Renderer */}
                    {directions && <DirectionsRenderer directions={directions} />}
                  </GoogleMap>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RideBookingForm;
