// src/Components/Navbar.jsx

import React,{useState} from "react";
import { useSelector } from "react-redux"; // Import useSelector
import img from "../Asset/profile.png";
import useLogout from "../hooks/useLogout";
import logo from "../Asset/woa.png";
import { FaSearch,FaBars } from "react-icons/fa";

function Navbar() {
  const handleLogout = useLogout();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar state


  // Access firstName and lastName from Redux store
  const firstName = useSelector((state) => state.user.firstName);
  const lastName = useSelector((state) => state.user.lastName);

  // Determine the display name
  const displayName =
    firstName && lastName
      ? `${firstName} ${lastName}`
      : firstName
      ? firstName
      : lastName
      ? lastName
      : "User"; // Fallback to "User" if names are not available

      const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar visibility
      };

  return (
    <nav className="bg-white shadow-md py-4 h-[8vh] px-6 w-full flex justify-between items-center fixed top-0 z-10">
      <div className="flex items-center">
      <h1 className="mb-2"> 
      <img src={logo} alt="WOA" className="w-15 h-10" /></h1>
      
              {/* Search Bar */}
              <div className="relative ml-20">
          <input
            type="text"
            placeholder="Search..."
            className="p-2 pl-4 rounded-md border border-gray-300 w-64 h-9"
          />
          {/* Search Icon */}
          <FaSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
       
        </div>
      </div>

      <div className="flex items-center">
        {/* Display the user's full name */}
        <span className="text-gray-500 mr-4">Hello, {displayName}! 👋</span>
        <img
          src={img}
          alt="User Avatar"
          className="rounded-full w-10 h-10"
        />
        <button
          className="border border-red-800 text-sm font-semibold rounded-md ml-8 p-1 text-red-800"
          onClick={handleLogout} // Simplified onClick handler
        >
          Logout
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
