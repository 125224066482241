// src/Pages/Rides/AllRides.jsx
import React, { useState, useEffect, useCallback, useRef } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import RideCard from "../../Components/RideCard";
import RideTable from "./RideDetails";
import {
  GoogleMap,
  LoadScript,
  Polyline,
  Marker,
} from "@react-google-maps/api";
import ridesAPI from "../../utils/API/rideApis";
import driverAPI from "../../utils/API/driverApis";
import customerApis from "../../utils/API/customerApis";
import useAuthToken from "../../hooks/customHooks";
import Modal from "react-modal";
import { toast } from "sonner";

// Define map container style
const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

// Define polyline options
const polylineOptions = {
  strokeColor: "#FF0000",
  strokeOpacity: 1.0,
  strokeWeight: 4,
};

// Modal styles
const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    padding: "20px",
  },
};

// Set the root element for accessibility
Modal.setAppElement("#root"); // Adjust based on your app's root element

function AllRides() {
  const [currentPage, setCurrentPage] = useState(1);
  const [isSticky, setIsSticky] = useState(true); // State for sticky map
  const [path, setPath] = useState([]); // State for the route path
  const ridesPerPage = 10;
  const mapRef = useRef(null);

  // Filter states
  const [status, setStatus] = useState("All Rides"); // Updated to lowercase
  const [searchString, setSearchString] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [driverId, setDriverId] = useState("");
  const [searchByDate, setSearchByDate] = useState(false);
  const [dateRange, setDateRange] = useState({ from: "", to: "" });

  // **New State for Ride Type**
  const [rideType, setRideType] = useState("all"); // Default to 'all'

  const [selectedRide, setSelectedRide] = useState(null);

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");
  const [cancellationLoading, setCancellationLoading] = useState(false);
  const [cancellationError, setCancellationError] = useState(null);
  const [cancellationSuccess, setCancellationSuccess] = useState(null);

  // State for Drivers and Customers
  const [drivers, setDrivers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [driversLoading, setDriversLoading] = useState(false);
  const [customersLoading, setCustomersLoading] = useState(false);

  // **New State for Completion Loading**
  const [completionLoading, setCompletionLoading] = useState(false);

  // Update initialCenter based on selectedRide
  const initialCenter = selectedRide
    ? {
        lat:
          selectedRide.type === "RideRequest"
            ? parseFloat(selectedRide.pickupLatitude)
            : parseFloat(selectedRide.pickupLat),
        lng:
          selectedRide.type === "RideRequest"
            ? parseFloat(selectedRide.pickupLongitude)
            : parseFloat(selectedRide.pickupLong),
      }
    : {
        lat: 25.2048,
        lng: 55.2708,
      };

  // Rides data state
  const [ridesData, setRidesData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  // Loading and error states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Authentication token (assuming you have an Auth context)
  const token = useAuthToken(); // Adjust based on your auth implementation

  // Function to fetch drivers
  const fetchDrivers = useCallback(async () => {
    setDriversLoading(true);
    try {
      const response = await driverAPI.getAllDrivers(
        1,
        1000,
        { searchCriteria: "", status: "" },
        token
      ); // Ensure this API exists
      if (response.error) {
        throw new Error(response.error);
      }
      console.log("DRIVERS: ", response.data.drivers);
      setDrivers(response.data.drivers); // Adjust based on API response
    } catch (err) {
      console.error("Error fetching drivers:", err);
      toast.warning(`Error fetching drivers.`, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setDriversLoading(false);
    }
  }, [token]);

  // Function to fetch customers
  const fetchCustomers = useCallback(async () => {
    setCustomersLoading(true);
    try {
      const response = await customerApis.getAllCustomers(
        1,
        1000,
        { searchCriteria: "", status: "" },
        token
      ); // Ensure this API exists
      if (response.error) {
        throw new Error(response.error);
      }
      console.log("CUSTOMERS: ", response.data.customers);
      setCustomers(response.data.customers); // Adjust based on API response
    } catch (err) {
      console.error("Error fetching customers:", err);
      toast.warning(`Error fetching customers.`, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setCustomersLoading(false);
    }
  }, [token]);

  // Fetch drivers and customers on component mount
  useEffect(() => {
    fetchDrivers();
    fetchCustomers();
  }, [fetchDrivers, fetchCustomers]);

  // **Updated fetchRides Function to Include rideType**
  const fetchRides = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const payload = {
        status: status !== "Accepted" ? status : "Accepted",
        searchString,
        customerId,
        driverId,
        rideType: rideType !== "all" ? rideType : undefined, // Include rideType if not 'all'
        ...(searchByDate && { dateRange }), // Include dateRange if searchByDate is true
      };

      const response = await ridesAPI.getRidesAPI(
        currentPage,
        ridesPerPage,
        payload,
        token
      );

      if (response.error) {
        throw new Error(response.error);
      }

      const data = response.data;

      // Assuming the API returns an object with 'rides' array and 'pagination' object
      setRidesData(data.rides);
      setTotalPages(data.pagination.totalPages);
    } catch (err) {
      console.error("Error fetching rides:", err);
      setError(err.message);
      toast.error(`Error fetching rides: ${err.message}`, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setLoading(false);
    }
  }, [
    status,
    searchString,
    customerId,
    driverId,
    rideType, // Added rideType to dependencies
    searchByDate,
    dateRange,
    currentPage,
    ridesPerPage,
    token,
  ]);

  useEffect(() => {
    fetchRides();
    setSelectedRide(null);
  }, [fetchRides]);

  // Function to handle pagination
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Helper function to generate pagination numbers
  const getPagination = () => {
    const totalNumbers = 7;
    const totalBlocks = totalNumbers + 2; // Adding first and last

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - 2);
      const endPage = Math.min(totalPages - 1, currentPage + 2);
      let pages = [];

      if (startPage > 2) {
        pages.push("...");
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage < totalPages - 1) {
        pages.push("...");
      }

      return [1, ...pages, totalPages];
    }

    return Array.from({ length: totalPages }, (_, i) => i + 1);
  };

  // Function to compute the route and set the path
  const computeRoute = useCallback(async () => {
    if (!selectedRide) {
      console.error("No selected ride");
      return;
    }

    // Initialize variables for coordinates
    let plat, plong, dlat, dlong;

    // Assign coordinate values based on rideType
    if (selectedRide.type === "RideRequest") {
      plat = selectedRide?.pickupLatitude;
      plong = selectedRide?.pickupLongitude;
      dlat = selectedRide?.destinationLatitude;
      dlong = selectedRide?.destinationLongitude;
    } else {
      plat = selectedRide?.pickupLat;
      plong = selectedRide?.pickupLong;
      dlat = selectedRide?.destinationLat;
      dlong = selectedRide?.destinationLong;
    }

    // Check for invalid coordinates
    if (
      isNaN(parseFloat(plat)) ||
      isNaN(parseFloat(plong)) ||
      isNaN(parseFloat(dlat)) ||
      isNaN(parseFloat(dlong))
    ) {
      console.error("Invalid coordinates", { plat, plong, dlat, dlong });
      toast.error("Invalid coordinates for the selected ride.", {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }

    // Define origin and destination using the correct coordinates
    const origin = {
      location: {
        latLng: {
          latitude: parseFloat(plat),
          longitude: parseFloat(plong),
        },
      },
    };

    const destination = {
      location: {
        latLng: {
          latitude: parseFloat(dlat),
          longitude: parseFloat(dlong),
        },
      },
    };

    try {
      const response = await fetch(
        "https://routes.googleapis.com/directions/v2:computeRoutes",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Goog-Api-Key": "AIzaSyDfYVUBrPAHGZb73XFV8ttWJkRcDhlEpzc", 
            "X-Goog-FieldMask":
              "routes.duration,routes.distanceMeters,routes.polyline.encodedPolyline",
          },
          body: JSON.stringify({
            origin,
            destination,
            travelMode: "DRIVE",
            routingPreference: "TRAFFIC_AWARE",
            computeAlternativeRoutes: false,
            routeModifiers: {
              avoidTolls: false,
              avoidHighways: false,
              avoidFerries: false,
            },
            languageCode: "en-US",
            units: "IMPERIAL",
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error.message ||
            `API request failed with status ${response.status}`
        );
      }

      const data = await response.json();
      const encodedPolyline = data.routes[0].polyline.encodedPolyline;

      // Decode the polyline using Google Maps API
      const decodedPath =
        window.google.maps.geometry.encoding.decodePath(encodedPolyline);

      // Convert to array of { lat, lng }
      const pathCoordinates = decodedPath.map((point) => ({
        lat: point.lat(),
        lng: point.lng(),
      }));

      setPath(pathCoordinates);
    } catch (error) {
      console.error("Error computing the route:", error);
      toast.error(`Error computing route: ${error.message}`, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  }, [selectedRide]);

  useEffect(() => {
    if (window.google && selectedRide) {
      computeRoute();
    }
  }, [selectedRide, computeRoute]);

  useEffect(() => {
    if (selectedRide && mapRef.current) {
      let lat, lng;
      if (selectedRide.type === "RideRequest") {
        lat = parseFloat(selectedRide.pickupLatitude);
        lng = parseFloat(selectedRide.pickupLongitude);
      } else {
        lat = parseFloat(selectedRide.pickupLat);
        lng = parseFloat(selectedRide.pickupLong);
      }
      if (!isNaN(lat) && !isNaN(lng)) {
        console.log("Panning to:", lat, lng); // Debugging
        mapRef.current.panTo({ lat, lng });
      } else {
        console.error("Invalid coordinates for panning:", selectedRide);
        toast.error("Invalid coordinates for panning the map.", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    }
  }, [selectedRide]);

  const handleLoad = useCallback(() => {
    computeRoute();
  }, [computeRoute]);

  const handleRideSelect = (ride) => {
    console.log("Selected Ride:", ride); // Debugging
    setSelectedRide(ride);
  };

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
    setCancellationReason("customer"); // Set default reason to "customer"
    setCancellationError(null);
    setCancellationSuccess(null);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // **Enhanced Function to Complete a Ride**
  const handleCompleteRide = async () => {
    if (!selectedRide) {
      toast.error("No ride selected.", {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }

    // Optional: Add a confirmation dialog
    const confirmCompletion = window.confirm(
      "Are you sure you want to mark this ride as completed?"
    );
    if (!confirmCompletion) return;

    try {
      setCompletionLoading(true); // Set loading state
      const response = await ridesAPI.completeRideAPI(
        { paymentState: "completed", tripId: selectedRide.tripId },
        token
      );

      // Adjust based on your API's response structure
      if (response.status === 200 || response.data?.status === "success") {
        toast.success("Ride has been completed.", {
          position: "top-right",
          autoClose: 5000,
        });
        await fetchRides();
      } else {
        // Handle unexpected success responses
        throw new Error(response.data?.message || "Unexpected response from the server.");
      }
    } catch (err) {
      console.error("Error completing ride:", err);
      toast.error(`Error completing ride: ${err.message}`, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setCompletionLoading(false); // Reset loading state
    }
  };

  // Function to handle ride cancellation
  const handleCancelRide = async () => {
    if (!cancellationReason.trim()) {
      setCancellationError("Please provide a cancellation reason.");
      return;
    }

    setCancellationLoading(true);
    setCancellationError(null);
    setCancellationSuccess(null);

    try {
      const response = await ridesAPI.cancelRideAPI(
        selectedRide.tripId,
        { cancellationReason },
        token
      );

      if (response.error) {
        throw new Error(response.error);
      }

      // Assuming the API returns the updated ride
      setCancellationSuccess("Ride has been successfully canceled.");
      toast.success("Ride has been successfully canceled.", {
        position: "top-right",
        autoClose: 5000,
      });

      // Optionally, refresh the rides list
      fetchRides();

      // Close the modal after a short delay
      setTimeout(() => {
        closeModal();
      }, 2000);
    } catch (err) {
      console.error("Error cancelling ride:", err);
      setCancellationError(err.message);
      toast.error(`Error cancelling ride: ${err.message}`, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setCancellationLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-12">
        <Sidebar currentSelection={{ main: "rides", sub: "AllRides" }} />
        {/* Main content area */}
        <div className="flex-1 bg-gray-50 overflow-y-auto h-screen">
          <div className="p-3">
            <h1 className="text-xl px-3 bg-customBlue py-5 text-gray-50">
              All Rides
            </h1>
            <div className="flex h-auto">
              <div className="w-[32%] bg-white p-3">
                {/* Search fields and filters here */}
                <div className="mb-3">
                  <label className="block text-gray-400 mb-1" htmlFor="search">
                    Search
                  </label>
                  <input
                    type="text"
                    id="search"
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                    className="border border-gray-400 rounded-lg px-3 py-1 w-full"
                    placeholder="Search by location"
                  />
                </div>

                {/* Search by Date Checkbox */}
                {/* <div className="flex items-center mb-3">
                  <input
                    type="checkbox"
                    id="search-by-date"
                    checked={searchByDate}
                    onChange={(e) => setSearchByDate(e.target.checked)}
                    className="h-4 w-4 text-green-600 border border-gray-400 rounded focus:ring-green-500"
                  />
                  <label
                    htmlFor="search-by-date"
                    className="ml-2 text-gray-400"
                  >
                    Search by Date
                  </label>
                </div> */}

                {searchByDate && (
                  <div className="mb-3">
                    <label className="block text-gray-400 mb-1">
                      Date Range
                    </label>
                    <div className="flex space-x-2">
                      <input
                        type="date"
                        value={dateRange.from}
                        onChange={(e) =>
                          setDateRange((prev) => ({
                            ...prev,
                            from: e.target.value,
                          }))
                        }
                        className="border border-gray-400 rounded-lg px-3 py-1 w-1/2"
                      />
                      <input
                        type="date"
                        value={dateRange.to}
                        onChange={(e) =>
                          setDateRange((prev) => ({
                            ...prev,
                            to: e.target.value,
                          }))
                        }
                        className="border border-gray-400 rounded-lg px-3 py-1 w-1/2"
                      />
                    </div>
                  </div>
                )}

                {/* First row of filters */}
                <div className="flex space-x-3 mb-3">
                  {/* By Driver Dropdown */}
                  <div className="w-1/2">
                    <label
                      className="block text-gray-400 mb-1"
                      htmlFor="driver"
                    >
                      By Driver
                    </label>
                    {driversLoading ? (
                      <p className="text-gray-500">Loading drivers...</p>
                    ) : (
                      <select
                        id="driver"
                        value={driverId}
                        onChange={(e) => setDriverId(e.target.value)}
                        className="border text-gray-600 border-gray-400 rounded-lg px-3 py-1 w-full"
                      >
                        <option value="">All Drivers</option>
                        {drivers.map((driver) => (
                          <option key={driver.id} value={driver.driverID}>
                            {driver.firstName} ({driver.driverID})
                          </option>
                        ))}
                      </select>
                    )}
                  </div>

                  {/* By Customer Dropdown */}
                  <div className="w-1/2">
                    <label
                      className="block text-gray-400 mb-1"
                      htmlFor="customer"
                    >
                      By Customer
                    </label>
                    {customersLoading ? (
                      <p className="text-gray-500">Loading customers...</p>
                    ) : (
                      <select
                        id="customer"
                        value={customerId}
                        onChange={(e) => setCustomerId(e.target.value)}
                        className="border text-gray-600 border-gray-400 rounded-lg px-3 py-1 w-full"
                      >
                        <option value="">All Customers</option>
                        {customers.map((customer) => (
                          <option key={customer.id} value={customer.id}>
                            {`${customer?.firstName} ${customer?.lastName}`} (
                            {customer.id})
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>

                {/* **Second row of filters with Type Field Connected to rideType State** */}
                <div className="flex space-x-3 mb-3">
                  {/* Status Field */}
                  <div className="w-1/2">
                    <label
                      className="block text-gray-400 mb-1"
                      htmlFor="status"
                    >
                      Status
                    </label>
                    <select
                      id="status"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      className="border text-gray-600 border-gray-400 rounded-lg px-3 py-1 w-full"
                    >
                      <option value="All Rides">All Rides</option>
                      <option value="Driver Not Assigned">
                        Driver Not Assigned
                      </option>
                      <option value="Accepted">Accepted</option>
                      <option value="Started">Started</option>
                      <option value="Waiting For Payment">
                        Waiting For Payment
                      </option>
                      <option value="Completed">Completed</option>
                      <option value="Cancelled">Cancelled</option>
                    </select>
                  </div>

                  {/* **Type Field Connected to rideType State** */}
                  <div className="w-1/2">
                    <label className="block text-gray-400 mb-1" htmlFor="type">
                      Type
                    </label>
                    <select
                      id="type"
                      value={rideType} // Controlled by rideType state
                      onChange={(e) => setRideType(e.target.value)} // Update rideType on change
                      className="border text-gray-600 border-gray-400 rounded-lg px-3 py-1 w-full"
                    >
                      <option value="all">All</option>
                      <option value="instant">Book For Now</option>
                      <option value="scheduled">Scheduled</option>
                      <option value="others">Book For Others</option>
                    </select>
                  </div>
                </div>

                {/* Action buttons */}
                <div className="flex justify-between px-10 mt-6 mb-6">
                  <button
                    onClick={() => {
                      setCurrentPage(1);
                      fetchRides();
                    }}
                    className="bg-green text-white font-bold py-2 px-4 rounded-lg hover:bg-customBlue-700"
                  >
                    Refresh
                  </button>
                  <button
                    onClick={() => {
                      // Clear all filters
                      setStatus("All Rides");
                      setSearchString("");
                      setCustomerId("");
                      setDriverId("");
                      setRideType("all"); // Reset rideType to 'all'
                      setSearchByDate(false);
                      setDateRange({ from: "", to: "" });
                      setCurrentPage(1);
                      fetchRides();
                    }}
                    className="bg-green text-white font-bold py-2 px-4 rounded-lg hover:bg-gray-500"
                  >
                    Clear Filters
                  </button>
                </div>

                {/* Loading and Error Messages */}
                {loading && (
                  <p className="text-center text-blue-500">Loading rides...</p>
                )}
                {error && (
                  <p className="text-center text-red-500">Error: {error}</p>
                )}

                {/* Ride Cards */}
                {!loading && !error && ridesData.length === 0 && (
                  <p className="text-center text-gray-500">No rides found.</p>
                )}
                {!loading &&
                  !error &&
                  ridesData.map((ride) => (
                    <RideCard
                      key={ride.id}
                      rideData={ride}
                      onSelect={handleRideSelect}
                    />
                  ))}

                {/* Pagination */}
                {!loading && !error && totalPages > 1 && (
                  <div className="flex justify-center mt-4 ml-32 space-x-1">
                    {/* Previous Page Button */}
                    <button
                      onClick={() => {
                        if (currentPage > 1) {
                          paginate(currentPage - 1);
                        }
                      }}
                      disabled={currentPage === 1}
                      className={`px-3 py-1 rounded ${
                        currentPage === 1
                          ? "bg-gray-300 text-gray-400 cursor-not-allowed"
                          : "bg-gray-300 text-gray-700 hover:bg-gray-400"
                      }`}
                    >
                      Prev
                    </button>

                    {/* Page Numbers */}
                    {getPagination().map((page, index) => {
                      if (page === "...") {
                        return (
                          <span key={`ellipsis-${index}`} className="px-3 py-1">
                            ...
                          </span>
                        );
                      }

                      return (
                        <button
                          key={page}
                          onClick={() => {
                            if (page !== currentPage) {
                              paginate(page);
                            }
                          }}
                          className={`mx-1 px-3 py-1 rounded ${
                            page === currentPage
                              ? "bg-green text-white"
                              : "bg-gray-300 text-gray-700 hover:bg-gray-400"
                          }`}
                        >
                          {page}
                        </button>
                      );
                    })}

                    {/* Next Page Button */}
                    <button
                      onClick={() => {
                        if (currentPage !== totalPages) {
                          paginate(totalPages);
                        }
                      }}
                      disabled={currentPage === totalPages}
                      className={`px-3 py-1 rounded ${
                        currentPage === totalPages
                          ? "bg-gray-300 text-gray-400 cursor-not-allowed"
                          : "bg-gray-300 text-gray-700 hover:bg-gray-400"
                      }`}
                    >
                      Last
                    </button>
                  </div>
                )}
              </div>

              {/* Map Section */}
              <div
                className={`relative flex-1 items-center px-6 py-16 h-[90vh] bg-white ${
                  isSticky ? "sticky top-0" : ""
                }`}
                id="map"
              >
                {/* Floating Cancel Ride Button */}
                {selectedRide &&
                  selectedRide.badge !== "Cancelled" &&
                  selectedRide.rideStatus !== "waitingfordriver" &&
                  selectedRide.status !== "completed" && (
                    <div className="absolute top-4 right-4 z-10">
                      <button
                        onClick={openModal}
                        className="bg-red-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-red-700"
                      >
                        Cancel Ride
                      </button>
                    </div>
                  )}

                {/* Floating Complete Ride Button */}
                {selectedRide &&
                  selectedRide.status === "waitingforpayment" && (
                    <div className="absolute top-4 right-50 z-10">
                      <button
                        onClick={handleCompleteRide}
                        className="bg-blue-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-700"
                        disabled={completionLoading}
                      >
                        {completionLoading ? "Completing..." : "Complete Ride"}
                      </button>
                    </div>
                  )}

                {/* Ride Details Table */}
                <div className="absolute w-[90%] bottom-20 left-10 right-0 bg-white p-1 max-h-[30%] z-50">
                  <RideTable ride={selectedRide} />
                </div>

                {/* Google Map */}
                <LoadScript
                  googleMapsApiKey="AIzaSyDfYVUBrPAHGZb73XFV8ttWJkRcDhlEpzc"
                  libraries={["geometry"]}
                  onLoad={handleLoad}
                  onError={(error) => {
                    console.error("Error loading Google Maps API:", error);
                    toast.error(
                      "Failed to load Google Maps. Please try again later.",
                      {
                        position: "top-right",
                        autoClose: 5000,
                      }
                    );
                    setError(
                      "Failed to load Google Maps. Please try again later."
                    );
                  }}
                >
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={initialCenter}
                    zoom={14}
                    onLoad={(map) => {
                      mapRef.current = map;
                    }}
                  >
                    {selectedRide && (
                      <>
                        <Marker
                          position={{
                            lat: parseFloat(
                              selectedRide?.pickupLat ||
                                selectedRide?.pickupLatitude
                            ),
                            lng: parseFloat(
                              selectedRide?.pickupLong ||
                                selectedRide?.pickupLongitude
                            ),
                          }}
                          label="A"
                        />

                        <Marker
                          position={{
                            lat: parseFloat(
                              selectedRide?.destinationLat ||
                                selectedRide?.destinationLatitude
                            ),
                            lng: parseFloat(
                              selectedRide?.destinationLong ||
                                selectedRide?.destinationLongitude
                            ),
                          }}
                          label="B"
                        />

                        {path.length > 0 && (
                          <Polyline path={path} options={polylineOptions} />
                        )}
                      </>
                    )}
                  </GoogleMap>
                </LoadScript>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Cancellation Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customModalStyles}
        contentLabel="Cancel Ride Modal"
      >
        <h2 className="text-2xl mb-4">Cancel Ride</h2>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="reason">
            Cancellation Reason
          </label>
          <select
            id="reason"
            value={cancellationReason}
            onChange={(e) => setCancellationReason(e.target.value)}
            className="w-full border border-gray-400 rounded-lg px-3 py-2"
          >
            <option value="customer">Due to customer</option>
            <option value="woa">WOA Issue</option>
          </select>
        </div>
        {cancellationError && (
          <p className="text-red-500 mb-2">{cancellationError}</p>
        )}
        {cancellationSuccess && (
          <p className="text-green mb-2">{cancellationSuccess}</p>
        )}
        <div className="flex justify-end space-x-3">
          <button
            onClick={closeModal}
            className="bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded-lg hover:bg-gray-400"
            disabled={cancellationLoading}
          >
            Cancel
          </button>
          <button
            onClick={handleCancelRide}
            className="bg-red-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-red-700"
            disabled={cancellationLoading}
          >
            {cancellationLoading ? "Cancelling..." : "Confirm Cancellation"}
          </button>
        </div>
      </Modal>
    </>
  );
}

export default AllRides;
