// src/pages/ManageScheduleRide/ManageScheduleRide.jsx

import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import TabsLgBasic from "../../Components/Tabs";
import { toast } from "sonner";
import rideApis from "../../utils/API/rideApis";
import useAuthToken from "../../hooks/customHooks";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import RescheduledRidesTable from "../../Components/RescheduledRidesTable";
import AssignedRidesTable from "../../Components/AssignedRidesTable";
import UnassignedRidesTable from "../../Components/UnassignedRidesTable";

function ManageScheduleRide() {
  const [activeTab, setActiveTab] = useState("unassigned");
  const [assignedRides, setAssignedRides] = useState([]);
  const [unassignedRides, setUnassignedRides] = useState([]);
  const [rescheduledRides, setRescheduledRides] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRide, setSelectedRide] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [assigning, setAssigning] = useState(false);
  const [expandedDrivers, setExpandedDrivers] = useState({});
  const [isReassigning, setIsReassigning] = useState(false);
  const [assigningDriverId, setAssigningDriverId] = useState(null);
  const token = useAuthToken();

  useEffect(() => {
    fetchScheduledRides();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchScheduledRides = async () => {
    setLoading(true);
    try {
      const response = await rideApis.getScheduledRidesAPI(token);
      if (response.data && response.data.success) {
        setAssignedRides(response.data.assignedRides);
        setUnassignedRides(response.data.unassignedRides);
        setRescheduledRides(response.data.rescheduledRides || []);
      } else {
        toast.error(response.data?.message || "Failed to fetch scheduled rides.");
      }
    } catch (error) {
      console.error("Error fetching scheduled rides:", error);
      toast.error("An error occurred while fetching rides.");
    }
    setLoading(false);
  };

  const openAssignModal = (ride) => {
    setSelectedRide(ride);
    setIsModalOpen(true);
    setIsReassigning(false);
    fetchAvailableDrivers(ride);
  };

  const openReassignModal = (ride) => {
    setSelectedRide(ride);
    setIsModalOpen(true);
    setIsReassigning(true);
    fetchAvailableDrivers(ride);
  };

  const closeAssignModal = () => {
    setIsModalOpen(false);
    setSelectedRide(null);
    setDrivers([]);
    setExpandedDrivers({});
  };

  const fetchAvailableDrivers = async (ride) => {
    try {
      const vehicleModel = ride.scheduled_vehicle_model;
      if (!vehicleModel) {
        toast.error("Selected ride does not have a vehicle model.");
        return;
      }

      const payload = { vehicleModel };
      const response = await rideApis.getDriverScheduleAPI(payload, token);
      if (response.data && response.data.drivers) {
        setDrivers(response.data.drivers);
      } else {
        toast.error(response.data?.message || "Failed to fetch drivers.");
      }
    } catch (error) {
      console.error("Error fetching available drivers:", error);
      toast.error("An error occurred while fetching drivers.");
    }
  };

  const handleAssignDriver = async (driverId) => {
    if (!selectedRide) return;
    setAssigningDriverId(driverId); // Set the current driver ID being assigned
    try {
      const payload = {
        rideId: selectedRide.id,
        driverId: driverId,
      };
      const response = await rideApis.assignDriverScheduleAPI(payload, token);
      if (response.status && response.status === 200) {
        toast.success("Driver assigned successfully!");
        fetchScheduledRides();
        closeAssignModal();
      } else {
        toast.error("Failed to assign driver.");
      }
    } catch (error) {
      console.error("Error assigning driver:", error);
      toast.error("An error occurred while assigning driver.");
    }
    setAssigningDriverId(null); // Reset after operation
  };


  const handleReassignDriver = async (newDriverId) => {
    if (!selectedRide) return;
    setAssigning(true);
    try {
      const payload = {
        rideId: selectedRide.id,
        newDriverId: newDriverId,
      };
      const response = await rideApis.reassignDriverScheduledAPI(payload, token);
      if (response.status && response.status === 200) {
        toast.success("Driver reassigned successfully!");
        fetchScheduledRides();
        closeAssignModal();
      } else {
        toast.error("Failed to reassign driver.");
      }
    } catch (error) {
      console.error("Error reassigning driver:", error);
      toast.error("An error occurred while reassigning driver.");
    }
    setAssigning(false);
  };

  const handleAcceptRescheduled = async (rideRequestId) => {
    try {
      const payload = {
        rideRequestId,
        action: true,
      };
      const response = await rideApis.acceptCancelRescheduledRideAPI(payload, token);
      if (response.status === 200) {
        toast.success("Trip rescheduled successfully!");
        fetchScheduledRides();
      } else {
        toast.error("Failed to reschedule.");
      }
    } catch (err) {
      console.error("Error rescheduling:", err);
      toast.error("An error occurred while rescheduling trip.");
    }
  };

  const handleCancelRescheduled = async (rideRequestId) => {
    try {
      const payload = {
        rideRequestId,
        action: false,
      };
      const response = await rideApis.acceptCancelRescheduledRideAPI(payload, token);
      if (response.status === 200) {
        toast.success("Rescheduling request declined successfully");
        fetchScheduledRides();
      } else {
        toast.error("Failed to decline.");
      }
    } catch (err) {
      console.error("Error declining:", err);
      toast.error("An error occurred while declining rescheduling request.");
    }
  };

  const toggleExpandDriver = (driverId) => {
    setExpandedDrivers((prev) => ({
      ...prev,
      [driverId]: !prev[driverId],
    }));
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar currentSelection={{ main: "rides", sub: "mngScheduledRides" }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto h-screen">
          <h1 className="text-2xl font-semibold mb-4">Manage Scheduled Rides</h1>

          <div className="mb-4">
            <TabsLgBasic activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>

          {loading ? (
            <div className="flex justify-center items-center">
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
            </div>
          ) : activeTab === "unassigned" ? (
            <UnassignedRidesTable
              rides={unassignedRides}
              onAssign={openAssignModal}
              type="unassigned"
            />
          ) : activeTab === "assigned" ? (
            // Pass onReassign to show a "Reassign" button in assigned rides
            <AssignedRidesTable
              rides={assignedRides}
              onReassign={openReassignModal}
              type="assigned"
            />
          ) : (
            <RescheduledRidesTable
              rides={rescheduledRides}
              onAccept={handleAcceptRescheduled}
              onDecline={handleCancelRescheduled}
            />
          )}

          {isModalOpen && (
            <div
              className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
              role="dialog"
              aria-modal="true"
              aria-labelledby="assignDriverModalTitle"
            >
              <div
                className="bg-white rounded-lg w-3/4 max-w-2xl p-6 overflow-y-auto max-h-full"
                role="document"
              >
                <h2
                  id="assignDriverModalTitle"
                  className="text-xl font-semibold mb-4"
                >
                  {isReassigning ? "Reassign Driver" : "Assign Driver"}
                </h2>
                {drivers.length === 0 ? (
                  <p className="text-center text-gray-500">No available drivers.</p>
                ) : (
                  <div className="min-w-full bg-white">
                    <table className="min-w-full bg-white">
                      <thead>
                        <tr>
                          <th className="py-2 px-4 border-b">Driver ID</th>
                          <th className="py-2 px-4 border-b">Phone</th>
                          <th className="py-2 px-4 border-b">Vehicle Model</th>
                          <th className="py-2 px-4 border-b">Scheduled Rides</th>
                          <th className="py-2 px-4 border-b">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {drivers.map((driver) => (
                          <React.Fragment key={driver.driverID}>
                            <tr className="border-b">
                              <td className="py-2 px-4 border-b">{driver.driverID}</td>
                              <td className="py-2 px-4 border-b">{driver.phone}</td>
                              <td className="py-2 px-4 border-b">{driver.vehicleModel}</td>
                              <td className="py-2 px-4 border-b">
                                {driver.scheduledRides.length > 0
                                  ? `${driver.scheduledRides.length} Ride${driver.scheduledRides.length > 1 ? "s" : ""}`
                                  : "No Rides"}
                                <button
                                  onClick={() => toggleExpandDriver(driver.driverID)}
                                  className="focus:outline-none mx-2"
                                  aria-expanded={expandedDrivers[driver.driverID] || false}
                                  aria-controls={`driver-schedule-${driver.driverID}`}
                                >
                                  {expandedDrivers[driver.driverID] ? (
                                    <FaChevronUp />
                                  ) : (
                                    <FaChevronDown />
                                  )}
                                </button>
                              </td>
                              <td className="py-2 px-4 border-b">
                                <button
                                  className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600 transition duration-200"
                                  onClick={() =>
                                    isReassigning
                                      ? handleReassignDriver(driver.driverID)
                                      : handleAssignDriver(driver.driverID)
                                  }
                                  disabled={assigningDriverId === driver.driverID} // Disable only the button being assigned
                                >
                                  {assigningDriverId === driver.driverID
                                    ? "Assigning..."
                                    : isReassigning
                                      ? "Reassign"
                                      : "Assign"}
                                </button>

                              </td>
                            </tr>
                            {expandedDrivers[driver.driverID] && (
                              <tr id={`driver-schedule-${driver.driverID}`}>
                                <td className="py-2 px-4 border-b"></td>
                                <td colSpan="5" className="py-2 px-4 border-b bg-gray-100">
                                  {driver.scheduledRides.length > 0 ? (
                                    <div>
                                      <h3 className="text-md font-semibold mb-4">Scheduled Rides:</h3>
                                      <div className="overflow-x-auto">
                                        <table className="min-w-full bg-white border border-gray-200">
                                          <thead>
                                            <tr>
                                              <th className="px-4 py-2 border-b">Ride ID</th>
                                              <th className="px-4 py-2 border-b">Pickup</th>
                                              <th className="px-4 py-2 border-b">Drop</th>
                                              <th className="px-4 py-2 border-b">Scheduled At</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {driver.scheduledRides.map((ride) => (
                                              <tr
                                                key={ride.id}
                                                className="hover:bg-gray-100"
                                              >
                                                <td className="px-4 py-2 border-b text-center">
                                                  {ride.id}
                                                </td>
                                                <td className="px-4 py-2 border-b">{ride.pickup}</td>
                                                <td className="px-4 py-2 border-b">{ride.drop}</td>
                                                <td className="px-4 py-2 border-b">
                                                  {new Date(ride.scheduledDateTime).toLocaleString()}
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  ) : (
                                    <p>No scheduled rides for this driver.</p>
                                  )}
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                <div className="mt-4 flex justify-end">
                  <button
                    className="bg-gray-500 text-white px-4 py-2 rounded mr-2 hover:bg-gray-600 transition duration-200"
                    onClick={closeAssignModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ManageScheduleRide;
