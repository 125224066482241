// src/utils/API/couponsApi.js

const BASE_URL = "https://apiwoadev.wheelsofavalon.com/api/offer";

/**
 * Fetch coupon data for the given customerId, page, and itemsPerPage
 * using native fetch (no Axios).
 *
 * @param {Number} page - The current page number.
 * @param {Number} itemsPerPage - Number of items to display per page.
 * @param {String} token - Auth token, e.g. from localStorage.
 * @returns {Object} The JSON response from the API.
 */
const getCoupons = async (page, itemsPerPage, token) => {
  // If "customerId" is always 4 or you get it from somewhere else,
  // adjust as needed. Example URL: /get-offer-pgn/4/<page>/<itemsPerPage>
  const customerId = 4;  

  // Construct URL, e.g.: https://apiwoadev.wheelsofavalon.com/api/offer/get-offer-pgn/4/1/5
  const url = `${BASE_URL}/get-offer-pgn/${customerId}/${page}/${itemsPerPage}`;

  // Prepare fetch options with headers including bearer token
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  // Check for non-2xx status codes
  if (!response.ok) {
    const errorBody = await response.json();
    throw new Error(errorBody.message || "Failed to fetch coupons.");
  }

  // Parse and return the JSON response
  const data = await response.json(); 
  // data => { success, message, data: { data: [...coupons], totalItems, totalPages, currentPage } }
  return data;
};

export default {
  getCoupons,
  // Additional coupon-related functions (create, edit, delete) can go here
};
