import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { toast } from "sonner";
import apis from "../../utils/API/driverApis";
import useAuthToken from "../../hooks/customHooks";

function NotificationHistory() {
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchCriteria, setSearchCriteria] = useState(""); 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = useAuthToken();

  // Fetch notifications
  const fetchNotifications = async (page, pageSize = 50) => {
    setLoading(true);
    setError(null);

    try {
      const response = await apis.getDriverNotifications(page, pageSize, token, searchCriteria);
      if (response?.data) {
        const data = response.data;
        setNotifications(data.notifications || []);
        setTotalPages(data.totalPages || 1);
      } else {
        throw new Error("Invalid response structure");
      }
    } catch (err) {
      setError("Failed to load notifications");
      toast.error("Unable to fetch notifications.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications(currentPage, 50);
  }, [currentPage, searchCriteria]);

  const handleSearchChange = (e) => {
    setSearchCriteria(e.target.value);
    setCurrentPage(1); 
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar currentSelection={{ main: "NotificationHistory", sub: null }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto h-screen">
          <div className="container mx-auto bg-gray-50 shadow-lg rounded-lg p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">Notification History</h3>
              <button
                onClick={() => fetchNotifications(currentPage)}
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
              >
                Refresh
              </button>
            </div>
            {/* Search input can be uncommented if needed */}
            {/* <input
              type="text"
              placeholder="Search notifications..."
              value={searchCriteria}
              onChange={handleSearchChange}
              className="w-full mb-4 p-2 border rounded-md"
            /> */}
            {loading ? (
              <p className="text-center">Loading...</p>
            ) : error ? (
              <p className="text-center text-red-500">{error}</p>
            ) : (
              <>
                {notifications.length === 0 ? (
                  <p className="text-center">No notifications found.</p>
                ) : (
                  <table className="w-full border">
                    <thead>
                      <tr>
                        {/* <th className="p-2">ID</th> */}
                        <th className="p-2">Request ID</th>
                        <th className="p-2">Recipient</th>
                        <th className="p-2">Status</th>
                        <th className="p-2">Action</th>
                        <th className="p-2">Trip Status</th>
                        <th className="p-2">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {notifications.map((notif) => (
                        <tr className="text-center" key={notif.notification_log_id}>
                          {/* <td className="p-2 ">{notif.notification_log_id}</td> */}
                          <td className="p-2">{notif.ride_req_id}</td>
                          <td className="p-2">{notif.recipient_id}</td>
                          <td className="p-2">{notif.delivery_status}</td>
                          <td className="p-2">
                            {notif.action ? notif.action : "No action"}
                          </td>
                          <td className="p-2">{notif.tripStatus}</td>
                          <td className="p-2">{new Date(notif.createdAt).toLocaleString()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                <div className="flex justify-center mt-4">
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                    <button
                      key={page}
                      onClick={() => setCurrentPage(page)}
                      className={`mx-1 px-3 py-1 rounded-md ${
                        page === currentPage ? "bg-blue-500 text-white" : "bg-gray-200"
                      }`}
                    >
                      {page}
                    </button>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationHistory;
