//src/utils/apiEndPoints.js
//"https://apiwoadev.wheelsofavalon.com";
// export const BASE_URL = "http://localhost:3000";
export const BASE_URL = "https://apiwoadev.wheelsofavalon.com";
export const ADMIN_ROUTE = "/api/adminpanel";
export const VEHICLE_ROUTE = "/vehicle";
export const GET_ALL_DRIVERS = "/getAllDrivers";
export const ADD_DRIVER = "/createDrivers";
export const EDIT_DRIVER = "/updateDriver";
export const DELETE_DRIVER = "/deleteDrivers";
export const ADD_BRAND = "/create-brands";
export const GET_BRANDS = "/getAll-brands";
export const EDIT_BRAND = "/edit-brands";
export const DELETE_BRAND = "/delete-brands";
export const ADD_MODEL = "/create-models";
export const GET_MODELS = "/getAll-models";
export const EDIT_MODEL = "/edit-models";
export const DELETE_MODEL = "/delete-models";
export const GET_COLORS = "/getAll-colors";
export const ADD_COLORS = "/create-colors";
export const EDIT_COLOR = "/edit-colors";
export const DELETE_COLOR = "/delete-colors";
export const ADD_VEHICLE = "/create-vehicle";
export const GET_VEHICLES = "/getall-vehicles";
export const GET_UNALLOCATED_DRIVERS = "/unallocatedDrivers";
export const EDIT_VEHICLE = "/edit-vehicle";
export const DELETE_VEHICLE = "/delete-vehicle";
export const ADMIN_AUTH = `${BASE_URL}${ADMIN_ROUTE}/authenticate`;
export const GET_USERS_BY_ROLE = `/get-users-by-roles`;
export const UPDATE_USER = `/update-user`;
export const DELETE_USER = `/delete-user`;
export const VEHICLE_ASSIGN_ROUTE = `/vehicleassign`;
export const ASSIGN_VEHICLE = `/assign-vehicle`;
export const REASSIGN_VEHICLE = `/reassign-vehicle`;
export const DEASSIGN_VEHICLE = "/deassign-vehicle";
export const GET_All_ASSIGNMENTS = "/get-assigned-vehicles";
export const GET_UNASSIGNED_VEHICLES = "/get-unassigned-vehicles";
export const ADD_CUSTOMER = "/createCustomer";
export const EDIT_CUSTOMER = "/updateCustomer";
export const DELETE_CUSTOMER = "/deleteCustomer";
export const GET_ALL_CUSTOMERS = "/getCustomers";
export const GET_ALL_FARES = "/getAllFares";
export const ADD_FARE = "/createFares";
export const EDIT_FARE = "/updateFares";
export const DELETE_FARE = "/deleteFares";
export const ADD_VEHICLE_CATEGORY = "/createVehicleCategories";
export const GET_ALL_VEHICLE_CATEGORIES = "/getAllVehicleCategories";
export const EDIT_VEHICLE_CATEGORY = "/updateVehicleCategories";
export const DELETE_VEHICLE_CATEGORY = "/deleteVehicleCategories";
export const GET_RIDES = "/getridebustatus";
export const DASHBOARD_METRICS = "/dashboard-metrics";
export const GET_ALL_SCHEDULED_RIDES = `/get-all-scheduled-rides`;
export const GET_DRIVER_FOR_SCHEDULED = `/get-driver-for-scheduled`;
export const ASSIGN_DRIVER_SCHEDULED = `/assign-driver-for-scheduled`;
export const REASSIGN_DRIVER_SHEDULED = "/reassign-driver-scheduled";
export const LOGOUT = `/logout`;
export const CANCEL_RIDE = `/cancelTrip`;
export const ACCEPTCANCEL_RESCHEDULED = `/accept-cancel-rescheduled-ride`;
export const GET_REVENUE = `/trip-revenue`;
export const COMPLETE_TRIP = `/admin-complete-trip`;
export const COUPONS_ROUTE = "/api/offer";