// src/pages/AddEditCustomer/AddEditCustomer.js

import { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import api from "../../utils/API/customerApis";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Toaster, toast } from "sonner";
import useAuthToken from "../../hooks/customHooks";

function AddEditCustomer() {
  const navigate = useNavigate();
  const location = useLocation();
  const { customerId } = useParams();
  const editElement = location.state?.customer;
  const token = useAuthToken();

  const [userDetails, setUserDetails] = useState({
    id: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    countryCode: "+91", // Default country code
    status: "enabled",
    email: "", // Include email field by default
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // useEffect to populate form data in edit mode
  useEffect(() => {
    if (customerId && editElement) {
      // Use regex to extract country code and phone number
      const phoneMatch = editElement.phoneNumber.match(/^(\+\d+)(\d{7,10})$/);
      const countryCode = phoneMatch ? phoneMatch[1] : "+91";
      const phoneNumber = phoneMatch
        ? phoneMatch[2]
        : editElement.phoneNumber || "";

      setUserDetails({
        id: editElement.id || "",
        firstName: editElement.firstName || "",
        lastName: editElement.lastName || "",
        phoneNumber: editElement.phoneNumber || "",
        countryCode: editElement.countryCode || "+91",
        status: editElement.status || "enabled",
        email: editElement.email || "",
      });
    }
  }, [customerId, editElement]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      // Email is only present in edit mode
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    } else if (name === "phoneNumber") {
      // Remove all non-digit characters
      let sanitizedValue = value.replace(/\D/g, "");

      // Limit to maximum of 10 digits
      if (sanitizedValue.length > 10) {
        sanitizedValue = sanitizedValue.slice(0, 10);
      }

      setUserDetails((prevDetails) => ({
        ...prevDetails,
        [name]: sanitizedValue,
      }));
    } else {
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }

    // Clear the error for the field being edited
    if (errors[name]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const validate = () => {
    const newErrors = {};

    // First Name Validation
    if (!userDetails.firstName?.trim()) {
      newErrors.firstName = "First name is required.";
    } else if (!/^[A-Za-z\s'-]+$/.test(userDetails.firstName)) {
      newErrors.firstName =
        "First name should contain only letters, spaces, apostrophes, or hyphens.";
    } else if (userDetails.firstName.length > 20) {
      newErrors.firstName = "First name cannot exceed 20 characters.";
    }

    // Last Name Validation
    if (!userDetails.lastName?.trim()) {
      newErrors.lastName = "Last name is required.";
    } else if (!/^[A-Za-z\s'-]+$/.test(userDetails.lastName)) {
      newErrors.lastName =
        "Last name should contain only letters, spaces, apostrophes, or hyphens.";
    } else if (userDetails.lastName.length > 20) {
      newErrors.lastName = "Last name cannot exceed 20 characters.";
    }

    // Country Code Validation
    if (!userDetails.countryCode?.trim()) {
      newErrors.countryCode = "Country code is required.";
    }

    // Phone Number Validation
    if (!userDetails.phoneNumber?.trim()) {
      newErrors.phoneNumber = "Phone number is required.";
    } else if (!/^\d{7,10}$/.test(userDetails.phoneNumber)) {
      newErrors.phoneNumber = "Phone number should be between 7 and 10 digits.";
    }

    // Email Validation (only in update mode)
    if (customerId && "email" in userDetails) {
      if (userDetails.email && userDetails.email.trim()) {
        if (!/\S+@\S+\.\S+/.test(userDetails.email)) {
          newErrors.email = "Invalid email format.";
        }
      }
      // Email is optional; no error if empty
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fix the highlighted errors.");
    }

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form data
    if (!validate()) return;

    setIsSubmitting(true);

    try {
      // Prepare payload with necessary fields
      const payload = {
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        status: userDetails.status,
        countryCode: userDetails.countryCode,
        phoneNumber: userDetails.phoneNumber,
      };

      // Include email in payload only if in update mode
      if (customerId && "email" in userDetails) {
        payload.email = userDetails.email;
      }

      console.log("Submitting payload:", payload); // For debugging

      let response;
      if (customerId) {
        // Update operation
        response = await api.updateCustomerAPI(payload, customerId, token);
      } else {
        // Add operation
        response = await api.addCustomerAPI(payload, token);
      }

      if (response.status === 200 || response.status === 201) {
        toast.success(
          customerId
            ? "Customer updated successfully!"
            : "Customer added successfully!"
        );
        navigate("/customer");
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while submitting the form.");
      }
      console.error("Error submitting the form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Remove toast notifications from per-field validation to avoid duplicates
  const handleBlur = (e) => {
    validateField(e.target.name, e.target.value);
  };

  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "firstName":
        if (!value.trim()) {
          error = "First name is required.";
        } else if (!/^[A-Za-z\s'-]+$/.test(value)) {
          error =
            "First name should contain only letters, spaces, apostrophes, or hyphens.";
        } else if (value.length > 20) {
          error = "First name cannot exceed 20 characters.";
        }
        break;
      case "lastName":
        if (!value.trim()) {
          error = "Last name is required.";
        } else if (!/^[A-Za-z\s'-]+$/.test(value)) {
          error =
            "Last name should contain only letters, spaces, apostrophes, or hyphens.";
        } else if (value.length > 20) {
          error = "Last name cannot exceed 20 characters.";
        }
        break;
      case "phoneNumber":
        if (!/^\d{7,10}$/.test(value)) {
          error = "Phone number should be between 7 and 10 digits.";
        }
        break;
      case "email":
        if (value && value.trim() && !/\S+@\S+\.\S+/.test(value)) {
          error = "Invalid email format.";
        }
        break;
      default:
        break;
    }

    // Update errors state and trigger toast if there's a new error
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors, [name]: error };

      if (error && prevErrors[name] !== error) {
        toast.error(error);
      }

      return updatedErrors;
    });
  };

  const handleReset = () => {
    if (customerId && editElement) {
      setUserDetails({
        id: editElement.id || "",
        firstName: editElement.firstName || "",
        lastName: editElement.lastName || "",
        phoneNumber: editElement.phoneNumber || "",
        countryCode: editElement.countryCode || "+91",
        status: editElement.status || "enabled",
        email: editElement.email || "",
      });
      setErrors({});
    } else {
      // Reset form fields for creation mode
      setUserDetails({
        id: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        countryCode: "+91",
        status: "enabled",
        email: "", // Include email field by default
      });
      setErrors({});
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar currentSelection={{ main: "users", sub: "customer" }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto h-screen">
          <div className="container mx-auto p-4">
            <div className="bg-stone-50 p-5 mb-8 rounded-xl mt-12">
              <Toaster />
              <div className="flex justify-between">
                <div className="text-xl py-2 my-2">
                  {customerId ? "Edit Customer" : "Add Customer"}
                </div>
                <button
                  type="button"
                  className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                  onClick={() => navigate("/customer")}
                >
                  Back
                </button>
              </div>

              <form onSubmit={handleSubmit} noValidate>
                <div className="grid grid-cols-3 gap-6 mt-4">
                  {/* First Name */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="firstName">First Name *</label>
                    <input
                      id="firstName"
                      type="text"
                      name="firstName"
                      placeholder="Enter first name"
                      value={userDetails.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`border rounded-xl p-2 ${
                        errors.firstName ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    {errors.firstName && (
                      <span className="text-red-500 text-sm">
                        {errors.firstName}
                      </span>
                    )}
                  </div>

                  {/* Last Name */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="lastName">Last Name *</label>
                    <input
                      id="lastName"
                      type="text"
                      name="lastName"
                      placeholder="Enter last name"
                      value={userDetails.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`border rounded-xl p-2 ${
                        errors.lastName ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    {errors.lastName && (
                      <span className="text-red-500 text-sm">
                        {errors.lastName}
                      </span>
                    )}
                  </div>

                  {/* Phone Number */}
                  <div className="flex flex-col text-gray-600 col-span-3">
                    <label htmlFor="phoneNumber">Phone Number *</label>
                    <div className="flex space-x-4 mt-1">
                      {/* Country Code Dropdown */}
                      <select
                        id="countryCode"
                        name="countryCode"
                        value={userDetails.countryCode || "+91"}
                        onChange={handleChange}
                        className={`border rounded-xl p-2 px-3 w-1/12 ${
                          errors.phoneNumber
                            ? "border-red-500"
                            : "border-gray-300"
                        }`}
                      >
                        {/* Add more country codes as needed */}
                        <option value="+91">+91</option>
                        <option value="+971">+971</option>
                        {/* Add more country codes as needed */}
                      </select>

                      {/* Phone Number Input */}
                      <input
                        id="phoneNumber"
                        type="tel"
                        name="phoneNumber"
                        placeholder="Enter Phone Number"
                        value={userDetails.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`border rounded-xl p-2 w-2/5 ${
                          errors.phoneNumber
                            ? "border-red-500"
                            : "border-gray-300"
                        }`}
                        inputMode="numeric"
                        pattern="\d*"
                        maxLength="10" // Restricts input to 10 digits
                      />
                    </div>
                    {errors.phoneNumber && (
                      <span className="text-red-500 text-sm">
                        {errors.phoneNumber}
                      </span>
                    )}
                  </div>

                  {/* Email (only in update mode) */}
                  {customerId && (
                    <div className="flex flex-col text-gray-600">
                      <label htmlFor="email">Email</label>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Enter Email Address"
                        value={userDetails.email || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`border rounded-xl p-2 ${
                          errors.email ? "border-red-500" : "border-gray-300"
                        }`}
                      />
                      {errors.email && (
                        <span className="text-red-500 text-sm">
                          {errors.email}
                        </span>
                      )}
                    </div>
                  )}

                  {/* Status */}
                  {/* Uncomment if you want to include status */}
                  {/* <div className="flex flex-col text-gray-600">
                    <label htmlFor="status">Status</label>
                    <select
                      id="status"
                      name="status"
                      value={userDetails.status}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-xl p-2"
                    >
                      <option value="enabled">Enabled</option>
                      <option value="disabled">Disabled</option>
                    </select>
                  </div> */}
                </div>

                <div className="flex gap-2 mt-4">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10 ${
                      isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  >
                    {isSubmitting
                      ? customerId
                        ? "Updating..."
                        : "Saving..."
                      : customerId
                      ? "Update"
                      : "Save"}
                  </button>
                  <button
                    type="button"
                    onClick={handleReset}
                    className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 h-10"
                  >
                    Reset
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEditCustomer;
