// src/components/Dashboard.jsx

import React, { useState, useEffect } from 'react';
import Navbar from "../Components/Navbar"; // Adjust this to the actual path
import Sidebar from "../Components/Sidebar"; // Adjust this to the actual path
import { Line } from 'react-chartjs-2';
import {
  Chart,
  CategoryScale,
  LinearScale,
  TimeScale, // Import TimeScale
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import 'chartjs-adapter-date-fns'; // Import the date adapter
import dashboardAPI from '../utils/API/dashboardApis';
import useAuthToken from '../hooks/customHooks';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import useLogout from '../hooks/useLogout'; // Custom hook for logout

// Register Chart.js components
Chart.register(
  CategoryScale,
  LinearScale,
  TimeScale, // Register TimeScale
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  const navigate = useNavigate();
  const performLogout = useLogout();
  const token = useAuthToken(); // Custom hook to retrieve the auth token

  // States for dynamic data
  const [dashboardStats, setDashboardStats] = useState({
    totalRevenue: 0,
    dailyRevenue: 0,
    monthlyRevenue: 0,
    yearlyRevenue: 0,
    totalRides: 0,
    totalAccepted: 0,
    totalStarted: 0,
    totalScheduled: 0
  });

  const [latestRides, setLatestRides] = useState([]);
  const [topDrivers, setTopDrivers] = useState([]);
  const [topCustomers, setTopCustomers] = useState([]);
  const [ridesGraph, setRidesGraph] = useState([]);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Rides',
        data: [],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        pointBorderColor: 'rgba(75, 192, 192, 1)',
        pointBackgroundColor: 'rgba(75, 192, 192, 1)',
        fill: true,
      },
    ],
  });

  const [chartOptions, setChartOptions] = useState({
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: 'Rides Graph',
      },
    },
    scales: {
      x: {
        type: 'time', // Use time scale
        time: {
          unit: 'day',
          displayFormats: {
            day: 'yyyy-MM-dd',
          },
        },
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Number of Rides',
        },
      },
    },
  });

  // Loading and error states
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Helper function to format labels
  const formatLabel = (key) => {
    return key
      .replace(/([A-Z])/g, ' $1') // Add space before capital letters
      .replace(/^./, str => str.toUpperCase()) // Capitalize the first character
      .trim();
  };

  // Helper function to format values with AED where applicable
  const formatValue = (key, value) => {
    if (typeof value === 'number') {
      const lowerKey = key.toLowerCase();
      if (lowerKey.includes('revenue') || lowerKey.includes('fare') || lowerKey.includes('spent')) {
        return `AED ${value.toLocaleString()}`;
      }
      return value.toLocaleString();
    }
    return value;
  };

  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      setError(null);

      // Fetch dashboard data from the API
      const response = await dashboardAPI.getDashboardAPI(token);

      if (response && response.data) {
        const data = response.data;

        // Update dashboardStats
        setDashboardStats({
          totalRevenue: data.totalRevenue,
          dailyRevenue: data.dailyRevenue,
          monthlyRevenue: data.monthlyRevenue,
          yearlyRevenue: data.yearlyRevenue,
          totalRides: data.totalRides,
          totalAccepted: data.totalAccepted,
          totalStarted: data.totalStarted,
          totalScheduled: data.totalScheduled
        });

        // Update latestRides
        setLatestRides(data.latestRides);

        // Update topDrivers
        setTopDrivers(data.topDrivers);

        // Update topCustomers
        setTopCustomers(data.topCustomers);

        // Update ridesGraph and chartData
        setRidesGraph(data.ridesGraphData); // Corrected key

        // Map dates to Date objects
        const labels = data.ridesGraphData.map(item => new Date(item.date));
        const rideCounts = data.ridesGraphData.map(item => item.rideCount);

        console.log('Labels:', labels); // For debugging
        console.log('Ride Counts:', rideCounts); // For debugging

        setChartData(prevChartData => ({
          ...prevChartData,
          labels: labels,
          datasets: [
            {
              ...prevChartData.datasets[0],
              data: rideCounts
            }
          ]
        }));
      } else {
        setError('Invalid API response');
      }

    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError('Failed to fetch dashboard data');
    } finally {
      setLoading(false);
    }
  };

  const handleNavigation = () => {
    navigate('/manage-scheduled-rides');
  };

  useEffect(() => {
    if (token) {
      fetchDashboardData();
    }
  }, [token]); // Re-fetch data if token changes

  useEffect(() => {
    // Push a new state to manage back navigation
    window.history.pushState(null, null, window.location.href);

    const handlePopState = () => {
      // Prompt the user for logout confirmation
      const confirmLogout = window.confirm("Do you want to logout?");
      if (confirmLogout) {
        performLogout().catch((error) => {
          console.error("Logout failed:", error);
          toast.error("Logout failed. Please try again.");
        });
      } else {
        // Re-push the current state to prevent navigating back
        window.history.pushState(null, null, window.location.href);
      }
    };

    // Add the event listener for popstate
    window.addEventListener('popstate', handlePopState);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [performLogout]);

  return (
    <>
      <Navbar />
      <div className="flex pt-16 h-[92vh]">
        <Sidebar currentSelection={{ main: "dashboard", sub: "null" }} />
        {/* Main content area */}
        <div className="flex-1 bg-gray-50 p-6 overflow-y-auto h-screen">
          <h1 className="text-xl font-semibold p-6">Dashboard</h1>

          {loading ? (
            <div className="text-center">Loading...</div>
          ) : error ? (
            <div className="text-center text-red-500">{error}</div>
          ) : (
            <>
              {/* Stats Section */}
              <div className="bg-gray-100 rounded-lg shadow p-6">
                <div className="h-[60vh] flex">
                  {/* Left Side - Stats Section */}
                  <div className="w-2/5 grid grid-cols-2 gap-4">
                    {/* Revenue and Ride Stats */}
                    {Object.entries(dashboardStats).map(([key, value], index) => (
                      <div key={index} className="bg-white p-4 rounded-lg shadow-md text-center min-h-[100px] flex flex-col justify-center">
                        <h3 className="text-sm font-medium text-gray-600">
                          {formatLabel(key)}
                        </h3>
                        <p className="text-base font-bold">
                          {formatValue(key, value)}
                        </p>
                      </div>
                    ))}
                  </div>

                  {/* Right Side - Latest Rides Section */}
                  <div className="w-3/5 ml-4">
                    {/* Ride List */}
                    <div className="bg-white p-4 rounded-lg shadow-md overflow-y-auto" style={{
                      height: "60vh"
                    }}>
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-xl font-bold">Scheduled Rides</h2>
                        <div className="flex space-x-4">
                          <button
                            onClick={fetchDashboardData}
                            className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600"
                          >
                            Refresh
                          </button>
                          <button className="bg-green-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-green-600" onClick={handleNavigation}>
                            Manage Scheduled Rides
                          </button>
                        </div>
                      </div>

                      {/* Ride Items */}
                      {latestRides.length === 0 ? (
                        <p className="text-center text-gray-500">No rides available.</p>
                      ) : (
                        latestRides.map((ride, index) => (
                          <div
                            key={ride.tripId || index} // Use tripId if available, else index
                            className="flex items-center gap-4 p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow border border-gray-200"
                          >
                            {/* Avatar */}
                            <div className="w-14 h-14 rounded-full bg-gradient-to-br from-blue-400 to-blue-600 flex items-center justify-center text-white text-xl font-bold">
                              👤
                            </div>
                            {/* Ride details */}
                            <div className="flex-1">
                              <p className="text-lg font-semibold text-gray-800">Trip ID: {ride.tripId || 'N/A'}</p>
                              <p className="text-sm text-gray-500">Customer ID: {ride.customerId}</p>
                              <p className="text-sm text-gray-500">Driver ID: {ride.driverId || 'Unassigned'}</p>
                              <p className="text-sm text-gray-500">
                                Date: {ride.scheduledDateTime ? new Date(ride.scheduledDateTime).toLocaleString() : 'N/A'}
                              </p>
                            </div>
                            {/* Status Badge */}
                            <div
                              className={`text-xs font-medium px-3 py-1 rounded-full ${
                                ride.rideStatus === 'completed'
                                  ? 'bg-green-100 text-green-600'
                                  : ride.rideStatus === 'initiated'
                                  ? 'bg-yellow-100 text-yellow-600'
                                  : 'bg-red-100 text-red-600'
                              }`}
                            >
                              {ride.rideStatus
                                ? `${ride.rideStatus.charAt(0).toUpperCase()}${ride.rideStatus.slice(1)}`
                                : 'Unknown'}
                            </div>

                            {/* Fare */}
                            <div>
                              <p className="text-lg font-medium px-3 py-1 rounded-full">
                                {ride.fare !== null && ride.fare !== undefined ? `AED ${ride.fare.toFixed(2)}` : 'Pending'}
                              </p>
                            </div>
                          </div>
                        ))
                      )}

                    </div>
                  </div>
                </div>

                {/* NEW SECTION: Chart and Tables */}
                <div className="bg-white p-6 rounded-lg shadow-md mt-10">
                  {/* Chart.js Rides Graph */}
                  <Line data={chartData} options={chartOptions} />

                  {/* Top Drivers and Customers Tables */}
                  <div className="grid grid-cols-2 gap-6 mt-6">
                    {/* Top Drivers */}
                    <div className="bg-white p-6 rounded-lg shadow-md">
                      <h2 className="text-lg font-bold mb-4">Top Drivers</h2>
                      {topDrivers.length === 0 ? (
                        <p className="text-center text-gray-500">No top drivers available.</p>
                      ) : (
                        <table className="min-w-full bg-white">
                          <thead>
                            <tr>
                              <th className="text-left py-2 px-4 border-b">Driver ID</th>
                              <th className="text-left py-2 px-4 border-b">Completed Trips</th>
                            </tr>
                          </thead>
                          <tbody>
                            {topDrivers.map((driver, index) => (
                              <tr key={index}>
                                <td className="py-2 px-4 border-b">{driver.driverId}</td>
                                <td className="py-2 px-4 border-b">{driver.completedTrips}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>

                    {/* Top Customers */}
                    <div className="bg-white p-6 rounded-lg shadow-md">
                      <h2 className="text-lg font-bold mb-4">Top Customers</h2>
                      {topCustomers.length === 0 ? (
                        <p className="text-center text-gray-500">No top customers available.</p>
                      ) : (
                        <table className="min-w-full bg-white">
                          <thead>
                            <tr>
                              <th className="text-left py-2 px-4 border-b">Customer ID</th>
                              <th className="text-left py-2 px-4 border-b">Total Spent (AED)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {topCustomers.map((customer, index) => (
                              <tr key={index}>
                                <td className="py-2 px-4 border-b">{customer.customerId}</td>
                                <td className="py-2 px-4 border-b">{customer.totalSpent?.toFixed(2)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Dashboard;
