// src/pages/Vehicles/AddEditVehicle.jsx

import { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import vehicleAPI from "../../utils/API/vehicleApis";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAuthToken from "../../hooks/customHooks";
import { toast } from "sonner";

function AddEditVehicle() {
  const navigate = useNavigate();
  const token = useAuthToken();
  const location = useLocation();
  const { vehicleId } = useParams();
  const editVehicle = location.state?.vehicle;

  // Initialize state with all necessary fields
  const [vehicleDetails, setVehicleDetails] = useState({
    modelMasterId: "",
    insuranceDetails: "",
    vehicleRegistration: "",
    vehicleStatus: "",
    color:"",
    weight: "",
    engineNumber: "",
    origin: "",
    chasisNumber: "",
    owner: "",
    tcNumber: "",
    placeOfIssue: "",
  });

  const [errors, setErrors] = useState({});
  const [availableModels, setAvailableModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [availableColors, setAvailableColors] = useState([]);

  // Fetch available colors for the color dropdown
  useEffect(() => {
    const fetchColors = async () => {
      try {
        const response = await vehicleAPI.getColorsAPI(1, 100, {}, token);
        setAvailableColors(response.data.result.items || []);
      } catch (err) {
        console.error("Failed to fetch colors:", err);
        alert("Failed to load colors. Please try again.");
      }
    };
    fetchColors();
  }, []);


  // Fetch available models and vehicle data
  useEffect(() => {
    const fetchInitialData = async () => {
      setLoadingData(true);
      try {
        // Fetch available models first
        const modelsResponse = await vehicleAPI.getModelsAPI(1, 100, {}, token);
        setAvailableModels(modelsResponse.data.items || []);

        // If editing, fetch vehicle data if not provided via location.state
        if (vehicleId) {
          if (editVehicle) {
            // If vehicle data is passed via state
            setVehicleDetails({
              modelMasterId: String(editVehicle.modelId) || "",
              insuranceDetails: editVehicle.insuranceDetails || "",
              vehicleRegistration: editVehicle.vehicleRegistration || "",
              vehicleStatus: editVehicle.vehicleStatus || "",
              weight: editVehicle.weight || "",
              engineNumber: editVehicle.engineNumber || "",
              origin: editVehicle.origin || "",
              chasisNumber: editVehicle.chasisNumber || "",
              owner: editVehicle.owner || "",
              tcNumber: editVehicle.tcNumber || "",
              placeOfIssue: editVehicle.placeOfIssue || "",
            });
          } else {
            // Fetch vehicle data by ID if not provided via state
            const vehicleResponse = await vehicleAPI.getVehicleById(
              vehicleId,
              token
            );
            const vehicleData = vehicleResponse.data;
            setVehicleDetails({
              modelMasterId: String(vehicleData.modelMasterId) || "",
              insuranceDetails: vehicleData.insuranceDetails || "",
              vehicleRegistration: vehicleData.vehicleRegistration || "",
              vehicleStatus: vehicleData.vehicleStatus || "",
              weight: vehicleData.weight || "",
              engineNumber: vehicleData.engineNumber || "",
              origin: vehicleData.origin || "",
              chasisNumber: vehicleData.chasisNumber || "",
              owner: vehicleData.owner || "",
              tcNumber: vehicleData.tcNumber || "",
              placeOfIssue: vehicleData.placeOfIssue || "",
            });
          }
        }
      } catch (err) {
        console.error("Failed to fetch data:", err);
        toast.error("Failed to load data. Please try again.");
      } finally {
        setLoadingData(false);
      }
    };

    fetchInitialData();
  }, [vehicleId, editVehicle, token]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    setVehicleDetails((prevDetails) => ({
      ...prevDetails,
      [name]: name === "modelMasterId" ? String(value) : value, // Ensure modelMasterId is a string
    }));
  };

  // Validation function
  const validate = () => {
    const newErrors = {};

    // Model ID: Required
    if (!vehicleDetails.modelMasterId) {
      newErrors.modelMasterId = "Please select a model.";
    }

    // Insurance Details: Required and must be a non-empty string
    if (!vehicleDetails.insuranceDetails.trim()) {
      newErrors.insuranceDetails = "Insurance details are required.";
    }

    // Vehicle Registration: Required and must match a pattern (e.g., license plate format)
    if (!vehicleDetails.vehicleRegistration.trim()) {
      newErrors.vehicleRegistration = "Vehicle registration is required.";
    } else if (
      !/^[A-Z0-9-]+$/i.test(vehicleDetails.vehicleRegistration.trim())
    ) {
      newErrors.vehicleRegistration =
        "Please enter a valid registration format.";
    }

    // Vehicle Status: Must be either "published" or "draft"
    if (!["published", "draft"].includes(vehicleDetails.vehicleStatus)) {
      newErrors.vehicleStatus = "Please select a valid status.";
    }

    // Weight: Optional, but if provided must be a positive number
    if (vehicleDetails.weight) {
      const weight = parseFloat(vehicleDetails.weight);
      if (isNaN(weight) || weight <= 0) {
        newErrors.weight = "Weight must be a positive number.";
      }
    }

    // Engine Number: Optional, but if provided must be alphanumeric
    if (
      vehicleDetails.engineNumber &&
      !/^[A-Z0-9]+$/i.test(vehicleDetails.engineNumber.trim())
    ) {
      newErrors.engineNumber = "Engine number must be alphanumeric.";
    }

    // Origin: Optional, can add specific validation if needed

    // Chasis Number: Optional, but if provided must be alphanumeric
    if (
      vehicleDetails.chasisNumber &&
      !/^[A-Z0-9]+$/i.test(vehicleDetails.chasisNumber.trim())
    ) {
      newErrors.chasisNumber = "Chasis number must be alphanumeric.";
    }

    // Owner: Optional, but if provided must be a non-empty string without numbers
    if (vehicleDetails.owner) {
      const trimmedOwner = vehicleDetails.owner.trim();
      if (!trimmedOwner) {
        newErrors.owner = "Owner name cannot be empty.";
      } else if (/\d/.test(trimmedOwner)) {
        newErrors.owner = "Owner name cannot contain numbers.";
      }
    }

    // TC Number: Optional, but if provided must follow a specific format if any

    // Place of Issue: Optional, but if provided must be a non-empty string without numbers
    if (vehicleDetails.placeOfIssue) {
      const trimmedPlace = vehicleDetails.placeOfIssue.trim();
      if (!trimmedPlace) {
        newErrors.placeOfIssue = "Place of issue cannot be empty.";
      } else if (/\d/.test(trimmedPlace)) {
        newErrors.placeOfIssue = "Place of issue cannot contain numbers.";
      }
    }

    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform validation
    if (!validate()) {
      // If validation fails, do not proceed
      return;
    }

    setLoading(true);

    try {
      let response;
      if (vehicleId) {
        // Editing existing vehicle
        response = await vehicleAPI.editVehicleAPI(
          vehicleDetails,
          vehicleId,
          token
        );
      } else {
        // Adding new vehicle
        response = await vehicleAPI.addVehicleAPI(vehicleDetails, token);
        console.log(response);
      }

      if (response.status === 200 || response.status === 201) {
        toast.success(
          `Vehicle ${vehicleId ? "updated" : "added"} successfully!`
        );
        navigate("/vehicles/all");
      } else {
        console.log("Something went wrong. Please try again.");
        const errorMessage =
          response.data?.message || "Something went wrong. Please try again.";
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      // Extract error message from the error object
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An error occurred while submitting the form.";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // Handle form reset or cancel
  const handleReset = () => {
    if (vehicleId) {
      // If editing, navigate back to the vehicles list to prevent accidental data loss
      navigate("/vehicles/all");
    } else {
      // If adding, clear the form
      setVehicleDetails({
        modelMasterId: "",
        insuranceDetails: "",
        vehicleRegistration: "",
        vehicleStatus: "",
        weight: "",
        engineNumber: "",
        origin: "",
        chasisNumber: "",
        owner: "",
        tcNumber: "",
        placeOfIssue: "",
      });
      setErrors({});
    }
  };

  // Conditional rendering to wait until data is loaded
  if (loadingData) {
    return (
      <>
        <Navbar />
        <div className="flex pt-16">
          <Sidebar
            currentSelection={{ main: "vehicles", sub: "allVehicles" }}
          />
          <div className="flex-1 bg-stone-200 p-6 flex justify-center items-center">
            <div className="text-gray-500">Loading...</div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar currentSelection={{ main: "vehicles", sub: "allVehicles" }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto">
          <div className="container mx-auto p-4">
            <div className="bg-stone-50 p-5 mb-8 rounded-xl mt-12">
              <form onSubmit={handleSubmit}>
                <div className="flex justify-between">
                  <div className="text-xl py-2 my-2">
                    {vehicleId ? "Edit Vehicle" : "Add New Vehicle"}
                  </div>
                  <div>
                    <button
                      type="button"
                      className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                      onClick={() => {
                        navigate("/vehicles/all");
                      }}
                    >
                      Back
                    </button>
                  </div>
                </div>

                {/* Updated Grid Layout: Changed to 3 columns */}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-4">
                  {/* Model */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="modelMasterId">Model *</label>
                    <select
                      id="modelMasterId"
                      name="modelMasterId"
                      value={vehicleDetails.modelMasterId}
                      onChange={handleChange}
                      required
                      className={`border rounded-xl p-2 ${errors.modelMasterId
                          ? "border-red-500"
                          : "border-gray-300"
                        }`}
                      aria-invalid={errors.modelMasterId ? "true" : "false"}
                    >
                      <option value="">Select Model</option>
                      {availableModels.map((model) => (
                        <option
                          key={model.modelMasterId}
                          value={String(model.modelMasterId)} // Ensure value is string
                        >
                          {model.modelName} ({model.year})
                        </option>
                      ))}
                    </select>
                    {errors.modelMasterId && (
                      <span className="text-red-500 text-sm">
                        {errors.modelMasterId}
                      </span>
                    )}
                  </div>

                  {/* Insurance Details */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="insuranceDetails">
                      Insurance Details *
                    </label>
                    <input
                      type="text"
                      id="insuranceDetails"
                      name="insuranceDetails"
                      placeholder="Enter insurance details"
                      value={vehicleDetails.insuranceDetails}
                      onChange={handleChange}
                      required
                      className={`border rounded-xl p-2 ${errors.insuranceDetails
                          ? "border-red-500"
                          : "border-gray-300"
                        }`}
                      aria-invalid={errors.insuranceDetails ? "true" : "false"}
                    />
                    {errors.insuranceDetails && (
                      <span className="text-red-500 text-sm">
                        {errors.insuranceDetails}
                      </span>
                    )}
                  </div>

                  {/* Vehicle Registration */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="vehicleRegistration">
                      Vehicle Registration *
                    </label>
                    <input
                      type="text"
                      id="vehicleRegistration"
                      name="vehicleRegistration"
                      placeholder="Enter registration number"
                      value={vehicleDetails.vehicleRegistration}
                      onChange={handleChange}
                      required
                      className={`border rounded-xl p-2 ${errors.vehicleRegistration
                          ? "border-red-500"
                          : "border-gray-300"
                        }`}
                      aria-invalid={
                        errors.vehicleRegistration ? "true" : "false"
                      }
                    />
                    {errors.vehicleRegistration && (
                      <span className="text-red-500 text-sm">
                        {errors.vehicleRegistration}
                      </span>
                    )}
                  </div>

                  {/* Color */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="color">Color *</label>
                    <select
                      id="color"
                      name="color"
                      value={vehicleDetails.color}
                      onChange={handleChange}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.color ? "border-red-500" : "border-gray-300"
                      }`}
                      aria-invalid={errors.color ? "true" : "false"}
                    >
                      <option value="">Select Color</option>
                      {availableColors?.map((color) => (
                        <option key={color.id} value={color.color}>
                          {color.color}
                        </option>
                      ))}
                    </select>
                    {errors.color && (
                      <span className="text-red-500 text-sm">
                        {errors.color}
                      </span>
                    )}
                  </div>

                  {/* Vehicle Status */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="vehicleStatus">Vehicle Status *</label>
                    <select
                      id="vehicleStatus"
                      name="vehicleStatus"
                      value={vehicleDetails.vehicleStatus}
                      onChange={handleChange}
                      required
                      className={`border rounded-xl p-2 ${errors.vehicleStatus
                          ? "border-red-500"
                          : "border-gray-300"
                        }`}
                      aria-invalid={errors.vehicleStatus ? "true" : "false"}
                    >
                      <option value="">Select Status</option>
                      <option value="published">Published</option>
                      <option value="draft">Draft</option>
                      {/* Add more status options as needed */}
                    </select>
                    {errors.vehicleStatus && (
                      <span className="text-red-500 text-sm">
                        {errors.vehicleStatus}
                      </span>
                    )}
                  </div>

                  {/* Weight */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="weight">Weight (kg)</label>
                    <input
                      type="number"
                      id="weight"
                      name="weight"
                      placeholder="Enter vehicle weight"
                      value={vehicleDetails.weight}
                      onChange={handleChange}
                      min="0"
                      step="0.1"
                      className={`border rounded-xl p-2 ${errors.weight ? "border-red-500" : "border-gray-300"
                        }`}
                      aria-invalid={errors.weight ? "true" : "false"}
                    />
                    {errors.weight && (
                      <span className="text-red-500 text-sm">
                        {errors.weight}
                      </span>
                    )}
                  </div>

                  {/* Engine Number */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="engineNumber">Engine Number</label>
                    <input
                      type="text"
                      id="engineNumber"
                      name="engineNumber"
                      placeholder="Enter engine number"
                      value={vehicleDetails.engineNumber}
                      onChange={handleChange}
                      className={`border rounded-xl p-2 ${errors.engineNumber
                          ? "border-red-500"
                          : "border-gray-300"
                        }`}
                      aria-invalid={errors.engineNumber ? "true" : "false"}
                    />
                    {errors.engineNumber && (
                      <span className="text-red-500 text-sm">
                        {errors.engineNumber}
                      </span>
                    )}
                  </div>

                  {/* Origin */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="origin">Origin</label>
                    <input
                      type="text"
                      id="origin"
                      name="origin"
                      placeholder="Enter origin"
                      value={vehicleDetails.origin}
                      onChange={handleChange}
                      className={`border rounded-xl p-2 ${errors.origin ? "border-red-500" : "border-gray-300"
                        }`}
                      aria-invalid={errors.origin ? "true" : "false"}
                    />
                    {errors.origin && (
                      <span className="text-red-500 text-sm">
                        {errors.origin}
                      </span>
                    )}
                  </div>

                  {/* Chasis Number */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="chasisNumber">Chassis Number</label>
                    <input
                      type="text"
                      id="chasisNumber"
                      name="chasisNumber"
                      placeholder="Enter chasis number"
                      value={vehicleDetails.chasisNumber}
                      onChange={handleChange}
                      className={`border rounded-xl p-2 ${errors.chasisNumber
                          ? "border-red-500"
                          : "border-gray-300"
                        }`}
                      aria-invalid={errors.chasisNumber ? "true" : "false"}
                    />
                    {errors.chasisNumber && (
                      <span className="text-red-500 text-sm">
                        {errors.chasisNumber}
                      </span>
                    )}
                  </div>

                  {/* Owner */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="owner">Owner</label>
                    <input
                      type="text"
                      id="owner"
                      name="owner"
                      placeholder="Enter owner name"
                      value={vehicleDetails.owner}
                      onChange={handleChange}
                      className={`border rounded-xl p-2 ${errors.owner ? "border-red-500" : "border-gray-300"
                        }`}
                      aria-invalid={errors.owner ? "true" : "false"}
                    />
                    {errors.owner && (
                      <span className="text-red-500 text-sm">
                        {errors.owner}
                      </span>
                    )}
                  </div>

                  {/* TC Number */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="tcNumber">TC Number</label>
                    <input
                      type="text"
                      id="tcNumber"
                      name="tcNumber"
                      placeholder="Enter TC number"
                      value={vehicleDetails.tcNumber}
                      onChange={handleChange}
                      className={`border rounded-xl p-2 ${errors.tcNumber ? "border-red-500" : "border-gray-300"
                        }`}
                      aria-invalid={errors.tcNumber ? "true" : "false"}
                    />
                    {errors.tcNumber && (
                      <span className="text-red-500 text-sm">
                        {errors.tcNumber}
                      </span>
                    )}
                  </div>

                  {/* Place of Issue */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="placeOfIssue">Place of Issue</label>
                    <input
                      type="text"
                      id="placeOfIssue"
                      name="placeOfIssue"
                      placeholder="Enter place of issue"
                      value={vehicleDetails.placeOfIssue}
                      onChange={handleChange}
                      className={`border rounded-xl p-2 ${errors.placeOfIssue
                          ? "border-red-500"
                          : "border-gray-300"
                        }`}
                      aria-invalid={errors.placeOfIssue ? "true" : "false"}
                    />
                    {errors.placeOfIssue && (
                      <span className="text-red-500 text-sm">
                        {errors.placeOfIssue}
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex gap-2 mt-6">
                  <button
                    type="submit"
                    className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                    disabled={loading}
                  >
                    {loading ? "Saving..." : "Save"}
                  </button>
                  <button
                    type="button"
                    className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 h-10"
                    onClick={handleReset}
                  >
                    {vehicleId ? "Cancel" : "Reset"}
                  </button>
                </div>
              </form>
              {loadingData && (
                <div className="flex justify-center p-4 text-gray-500">
                  Loading...
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEditVehicle;
