// src/components/User/AddEditUser.jsx
import { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import usersApi from "../../utils/API/userApis";
import useAuthToken from "../../hooks/customHooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
// Example icons for toggling password visibility
import { FiEye, FiEyeOff } from "react-icons/fi";

// Reusable Input Component
const InputField = ({
  label,
  type = "text",
  id,
  name,
  placeholder,
  value,
  onChange,
  error,
  autoComplete,
}) => (
  <div className="flex flex-col text-gray-600">
    <label htmlFor={id}>{label}</label>
    <input
      type={type}
      id={id}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={`border rounded-xl p-2 ${
        error ? "border-red-500" : "border-gray-300"
      }`}
      autoComplete={autoComplete}
    />
    {error && <span className="text-red-500 text-sm">{error}</span>}
  </div>
);

function AddEditUser() {
  const token = useAuthToken();
  const location = useLocation();
  const navigate = useNavigate();
  const { userId } = useParams();
  const editUser = location.state?.user;

  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    status: "enabled",
    countryCode: "+91",
    phoneNumber: "",
    // Remove password from the main user details if editing
    password: "",
    orgName: "",
    roles: "",
  });

  const [initialData, setInitialData] = useState(null);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // ---- NEW STATE FOR PASSWORD CHANGE ----
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  // For toggling visibility
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  // ---------------------------------------

  // Populate form fields from editUser if in edit mode
  useEffect(() => {
    if (userId && editUser) {
      setUserDetails({
        firstName: editUser.firstName || "",
        lastName: editUser.lastName || "",
        email: editUser.email || "",
        status: editUser.status || "enabled",
        countryCode: editUser.countryCode || "+91",
        phoneNumber: editUser.phoneNumber || "",
        // We do NOT automatically fill in the password for edit
        password: "",
        orgName: editUser.org || "",
        roles: editUser.roles || "",
      });
      setInitialData({
        firstName: editUser.firstName || "",
        lastName: editUser.lastName || "",
        email: editUser.email || "",
        status: editUser.status || "enabled",
        countryCode: editUser.countryCode || "+91",
        phoneNumber: editUser.phoneNumber || "",
        password: "",
        orgName: editUser.org || "",
        roles: editUser.roles || "",
      });
    }
  }, [userId, editUser]);

  // Handle input changes for main form
  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    // Clear the error for the field being edited
    if (errors[name]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  // Main form validation
  const validate = () => {
    const newErrors = {};

    // First Name Validation
    if (!userDetails.firstName.trim()) {
      newErrors.firstName = "First Name is required.";
    } else if (!/^[A-Za-z\s'-]+$/.test(userDetails.firstName)) {
      newErrors.firstName =
        "First Name should contain only letters, spaces, apostrophes, or hyphens.";
    }

    // Last Name Validation
    if (!userDetails.lastName.trim()) {
      newErrors.lastName = "Last Name is required.";
    } else if (!/^[A-Za-z\s'-]+$/.test(userDetails.lastName)) {
      newErrors.lastName =
        "Last Name should contain only letters, spaces, apostrophes, or hyphens.";
    }

    // Email Validation
    if (!userDetails.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (
      !/^[A-Za-z0-9._%+-]+@wheelsofavalon\.com$/.test(userDetails.email)
    ) {
      newErrors.email = "Email must end with @wheelsofavalon.com.";
    }

    // Phone Number Validation
    if (!userDetails.phoneNumber.trim()) {
      newErrors.phoneNumber = "Phone Number is required.";
    } else if (!/^[0-9]{7,15}$/.test(userDetails.phoneNumber)) {
      newErrors.phoneNumber =
        "Phone number should contain between 7 and 15 digits.";
    }

    // Password Validation – only required for Add Mode
    if (!userId) {
      if (!userDetails.password) {
        newErrors.password = "Password is required for a new user.";
      } else if (userDetails.password.length < 8) {
        newErrors.password = "Password must be at least 8 characters long.";
      }
    }

    // Organization Name Validation
    if (!userDetails.orgName.trim()) {
      newErrors.orgName = "Organization Name is required.";
    }

    // Roles Validation
    if (!userDetails.roles.trim()) {
      newErrors.roles = "Role is required.";
    }

    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  // Main form submission (create or update user – excluding password change)
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      toast.error("Please correct the errors.");
      return;
    }

    setIsSubmitting(true);

    // Prepare the payload
    const payload = {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
      countryCode: userDetails.countryCode,
      phoneNumber: userDetails.phoneNumber,
      orgName: userDetails.orgName,
      status: userDetails.status,
      ...(userId
        ? { rolesName: userDetails.roles }
        : { roles: userDetails.roles }),
    };

    // Only include password in payload if we're creating a new user
    // or if there's a password typed in (which we no longer do for edit).
    if (!userId && userDetails.password) {
      payload.password = userDetails.password;
    }

    try {
      let response;
      if (userId) {
        // Edit mode
        response = await usersApi.updateUserAPI(payload, token, userId);
      } else {
        // Add mode
        response = await usersApi.addUserAPI(payload, token);
      }

      const { data } = response;
      if (data.success) {
        toast.success(
          userId ? "User updated successfully!" : "User added successfully!"
        );
        navigate("/users");
        if (!userId) {
          // Reset form if adding new user
          setUserDetails({
            firstName: "",
            lastName: "",
            email: "",
            status: "enabled",
            countryCode: "+91",
            phoneNumber: "",
            password: "",
            orgName: "",
            roles: "",
          });
          setErrors({});
        }
      } else {
        // Handle server-side validation errors, etc.
        if (data.errors) {
          if (Array.isArray(data.errors)) {
            data.errors.forEach((err) => {
              toast.error(err.message);
            });
          } else {
            toast.error(data.message || "Something went wrong!");
          }
        } else {
          toast.error(data.message || "Something went wrong!");
        }
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "An unexpected error occurred.";
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle form reset
  const handleReset = () => {
    if (userId && initialData) {
      setUserDetails(initialData);
      setErrors({});
      toast.info("Form has been reset.");
    } else {
      setUserDetails({
        firstName: "",
        lastName: "",
        email: "",
        status: "enabled",
        countryCode: "+91",
        phoneNumber: "",
        password: "",
        orgName: "",
        roles: "",
      });
      setErrors({});
      toast.info("Form has been reset.");
    }
  };

  // ---- NEW: HANDLE PASSWORD CHANGE SEPARATELY ----
  const handlePasswordChangeSubmit = async (e) => {
    e.preventDefault();

    // Basic validations – you can expand on these
    if (!currentPassword) {
      toast.error("Please enter your old password.");
      return;
    }
    if (!newPassword) {
      toast.error("Please enter a new password.");
      return;
    }
    if (newPassword.length < 8) {
      toast.error("New password must be at least 8 characters long.");
      return;
    }

    try {
      // Call separate API for changing the password
      const response = await usersApi.editUserPasswordAPI(
        {
          currentPassword,
          newPassword,
        },
        token,
        userId
      );

      if (response.data.success) {
        toast.success("Password changed successfully!");
        // Clear fields and close the password change form
        setCurrentPassword("");
        setNewPassword("");
        setShowChangePassword(false);
      } else {
        toast.error(response.data.message || "Could not change password.");
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "An unexpected error occurred.";
      toast.error(errorMessage);
    }
  };

  // Toggle password visibility
  const togglecurrentPasswordVisibility = () => {
    setCurrentPasswordVisible(!currentPasswordVisible);
  };
  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto">
          <div className="container mx-auto p-4">
            <div className="bg-stone-50 p-5 mb-8 rounded-xl mt-12">
              <div className="flex justify-between">
                <div className="text-xl py-2 my-2">
                  {userId ? "Edit User" : "Add User"}
                </div>
                <div>
                  <button
                    onClick={() => navigate("/users")}
                    className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                  >
                    Back
                  </button>
                </div>
              </div>

              {/* Error Messages Box */}
              {Object.keys(errors).length > 0 && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                  <strong className="font-bold">
                    Please fix the following errors:
                  </strong>
                  <ul className="list-disc list-inside mt-2">
                    {Object.values(errors).map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}

              {/* Loading State */}
              {isSubmitting ? (
                <div className="flex justify-center items-center">
                  <div className="loader">Submitting...</div>
                </div>
              ) : (
                <>
                  {/* MAIN FORM */}
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="grid grid-cols-3 gap-6 mt-4">
                      <InputField
                        label="First Name *"
                        id="firstName"
                        name="firstName"
                        placeholder="Enter first name"
                        value={userDetails.firstName}
                        onChange={handleChange}
                        error={errors.firstName}
                      />

                      <InputField
                        label="Last Name *"
                        id="lastName"
                        name="lastName"
                        placeholder="Enter last name"
                        value={userDetails.lastName}
                        onChange={handleChange}
                        error={errors.lastName}
                      />

                      <InputField
                        label="Email *"
                        type="email"
                        id="email"
                        name="email"
                        placeholder="user@wheelsofavalon.com"
                        value={userDetails.email}
                        onChange={handleChange}
                        error={errors.email}
                        autoComplete={userId ? "email" : "new-password"}
                      />

                      {/* Grouped Country Code & Phone */}
                      <div className="flex flex-col text-gray-600 col-span-3">
                        <label htmlFor="phoneNumber">Phone Number *</label>
                        <div className="flex space-x-4 mt-1">
                          <select
                            id="countryCode"
                            name="countryCode"
                            value={userDetails.countryCode}
                            onChange={handleChange}
                            className={`border rounded-xl p-2 w-1/4 ${
                              errors.phoneNumber
                                ? "border-red-500"
                                : "border-gray-300"
                            }`}
                          >
                            <option value="+91">India (+91)</option>
                            <option value="+1">United States (+1)</option>
                            <option value="+44">United Kingdom (+44)</option>
                            <option value="+61">Australia (+61)</option>
                            <option value="+971">UAE (+971)</option>
                          </select>
                          <input
                            type="tel"
                            id="phoneNumber"
                            name="phoneNumber"
                            placeholder="Enter phone number"
                            value={userDetails.phoneNumber}
                            onChange={(e) => {
                              // Only allow digits
                              if (/^\d*$/.test(e.target.value)) {
                                handleChange(e);
                              }
                            }}
                            className={`border rounded-xl p-2 w-3/4 ${
                              errors.phoneNumber
                                ? "border-red-500"
                                : "border-gray-300"
                            }`}
                            inputMode="numeric"
                            pattern="\d*"
                          />
                        </div>
                        {errors.phoneNumber && (
                          <span className="text-red-500 text-sm">
                            {errors.phoneNumber}
                          </span>
                        )}
                      </div>

                      {/* Password input only if adding a new user */}
                      {!userId && (
                        <InputField
                          label="Password *"
                          type="password"
                          id="password"
                          name="password"
                          placeholder="Enter password (min 8 characters)"
                          value={userDetails.password}
                          onChange={handleChange}
                          error={errors.password}
                          autoComplete="new-password"
                        />
                      )}

                      <InputField
                        label="Organization Name *"
                        id="orgName"
                        name="orgName"
                        placeholder="Enter organization name"
                        value={userDetails.orgName}
                        onChange={handleChange}
                        error={errors.orgName}
                      />

                      {/* Roles */}
                      <div className="flex flex-col text-gray-600">
                        <label htmlFor="roles">Roles *</label>
                        <select
                          id="roles"
                          name="roles"
                          value={userDetails.roles}
                          onChange={handleChange}
                          className={`border rounded-xl p-2 ${
                            errors.roles ? "border-red-500" : "border-gray-300"
                          }`}
                        >
                          <option value="" disabled>
                            Select Role
                          </option>
                          <option value="SUPERADMIN">SUPERADMIN</option>
                          <option value="ADMIN">ADMIN</option>
                          <option value="ACCOUNTANT">ACCOUNTANT</option>
                        </select>
                        {errors.roles && (
                          <span className="text-red-500 text-sm">
                            {errors.roles}
                          </span>
                        )}
                      </div>
                    </div>

                    {/* Submit and Reset Buttons */}
                    <div className="flex gap-2 mt-4">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10 ${
                          isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        aria-label={userId ? "Update User" : "Save User"}
                      >
                        {isSubmitting
                          ? "Submitting..."
                          : userId
                          ? "Update"
                          : "Save"}
                      </button>
                      <button
                        type="button"
                        onClick={handleReset}
                        className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 h-10"
                      >
                        Reset
                      </button>
                    </div>
                  </form>

                  {/* SHOW CHANGE PASSWORD BUTTON IF IN EDIT MODE */}
                  {userId && (
                    <div className="mt-8">
                      {!showChangePassword ? (
                        <button
                          onClick={() => setShowChangePassword(true)}
                          className="bg-blue-500 text-white px-4 py-2 rounded"
                        >
                          Change Password
                        </button>
                      ) : (
                        <div className="bg-white p-4 rounded border">
                          <h3 className="text-lg font-semibold mb-4">
                            Change Password
                          </h3>
                          <form onSubmit={handlePasswordChangeSubmit}>
                            {/* Old Password */}
                            <div className="relative mb-4">
                              <label
                                htmlFor="currentPassword"
                                className="block text-gray-700 mb-1"
                              >
                                Old Password
                              </label>
                              <input
                                id="currentPassword"
                                type={currentPasswordVisible ? "text" : "password"}
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                className="border rounded-xl p-2 w-full pr-10"
                                placeholder="Enter old password"
                              />
                              {/* Eye icon */}
                              <div
                                className="absolute top-9 right-3 cursor-pointer"
                                onClick={togglecurrentPasswordVisibility}
                              >
                                {currentPasswordVisible ? (
                                  <FiEyeOff />
                                ) : (
                                  <FiEye />
                                )}
                              </div>
                            </div>

                            {/* New Password */}
                            <div className="relative mb-4">
                              <label
                                htmlFor="newPassword"
                                className="block text-gray-700 mb-1"
                              >
                                New Password
                              </label>
                              <input
                                id="newPassword"
                                type={newPasswordVisible ? "text" : "password"}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                className="border rounded-xl p-2 w-full pr-10"
                                placeholder="Enter new password"
                              />
                              {/* Eye icon */}
                              <div
                                className="absolute top-9 right-3 cursor-pointer"
                                onClick={toggleNewPasswordVisibility}
                              >
                                {newPasswordVisible ? (
                                  <FiEyeOff />
                                ) : (
                                  <FiEye />
                                )}
                              </div>
                            </div>

                            <div className="flex space-x-4">
                              <button
                                type="submit"
                                className="bg-green-600 text-white px-4 py-2 rounded"
                              >
                                Update Password
                              </button>
                              <button
                                type="button"
                                className="bg-gray-400 text-white px-4 py-2 rounded"
                                onClick={() => {
                                  setShowChangePassword(false);
                                  setCurrentPassword("");
                                  setNewPassword("");
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEditUser;
