import React, { useState } from "react";
import {
  FaHome,
  FaBorderAll,

  FaCar,
  FaUsers,
  FaMap,
  FaMoneyBill,
  FaCog,
  FaBars,
  FaChevronDown,
  FaChevronUp,
  FaWallet,
  FaFileAlt,
  FaCarSide,
  FaAddressBook,
  FaBell,
  FaBook, // Importing the new icon for "Page"
} from "react-icons/fa";
import { MdAssignment } from "react-icons/md";

import { MdOutlineSecurity } from "react-icons/md";
import { ImCoinDollar } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleCategory } from "../State/sideBarSlice";

function Sidebar({ currentSelection = { main: "", sub: "" } }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [isOpen, setIsOpen] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);
  

  const expanded = useSelector((state) => state.sidebar.expandedList);

  // const toggleSidebar = (e) => {
  //   e.stopPropagation(); // Prevents the sidebar toggle click from propagating
  //   setIsOpen((prevState) => !prevState);
  
  // };

  const toggleSidebar = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  const toggleSublist = (category) => {
    dispatch(toggleCategory(category));
  };

  return (
    <>
    <div
      className={`flex h-screen transition-all ${
        isCollapsed ? "bg-lightBlue" : "bg-darkBlue"
      }`}
    ></div>

     
      {/* Sidebar for larger screens and when open on smaller screens */}
        {/* Sidebar content */}
      <div
        className={`bg-customBlue  h-screen fixed lg:static lg:translat ${
          isCollapsed ? "w-16" : "w-64"}
        transition-all`}
        style={{ overflowY: "auto" }}
      >
        
<div className="p-4">
          <FaBars
    
    onClick={toggleSidebar}
            className={`cursor-pointer text-white ${isCollapsed ? "ml-2" : "ml-0"}`}
          />
        </div>
        
      

        <ul className="space-y-4 pb-10">
          {/* Dashboard */}
          <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "dashboard"
                  ? "text-white font-bold"
                  : "text-white"
              } px-6 py-2 pt-10  hover:bg-slate-300`}
              onClick={(e) => {
                // If the arrow icon is clicked, toggle the sublist
                if (e.target.closest(".toggle-icon")) return; // Prevent the click if it's on the arrow icon
                navigate("/Dashboard"); // Otherwise navigate to Dashboard
              }}
            >
              <div className="flex items-center">
                <FaBorderAll className="mr-2" />
                {!isCollapsed && "Dashboard"}
              </div>
           
          {/* Heatmap */}
          {/* <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "Heatmap"
                  ? "text-green-600 font-bold"
                  : "text-gray-600"
              } px-6 py-2`}
              onClick={() => navigate("/Heatmap")}
            >
              <div className="flex items-center">
                <FaMap className="mr-2" />
                Heatmap
              </div>
            </div>
          </li> */}
          {/* Live Users */}
          {/* <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "liveusers"
                  ? "text-green-600 font-bold"
                  : "text-gray-600"
              } px-6 py-2 hover:bg-gray-200`}
              onClick={() => navigate("/liveusers")}
            >
              <div className="flex items-center">
                <FaMap className="mr-2" />
                Live Users
              </div>
            </div>
          </li> */}
          {/* Users & Customers */}
          {!isCollapsed &&(

          <div
         className="toggle-icon cursor-pointer p-0.25 rounded-full border border-white flex items-center justify-center text-xs"
        onClick={() => toggleSublist("dashboard")}
      >
        {!isCollapsed && (expanded.dashboard ? <FaChevronUp /> : <FaChevronDown />)}
      </div>
          )}
    </div>
    {expanded.dashboard && !isCollapsed &&(
      <ul className="bg-customBlue text-white">
        <li className="w-full hover:bg-slate-300">
                  <a
                    href="/users"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "users"
                        ? "text-white font-bold"
                  : "text-white"
                    }`}
                    onClick={() => {
                      navigate("/users");
                    }}
                  >
                    Users
                  </a>
                </li>
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/customer"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "customer"
                      ? "text-white font-bold"
                  : "text-white"
                    }`}
                    onClick={() => {
                      navigate("/customer");
                    }}
                  >
                    Customer
                  </a>
                </li>
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/driver"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "driver"
                       ? "text-white font-bold"
                  : "text-white"
                    }`}
                    onClick={() => {
                      navigate("/driver");
                    }}
                  >
                    Driver
                  </a>
                </li>
              </ul>
            )}
          </li>
          {/* Assignments */}
          <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "assignments"
                  ? "text-white font-bold"
                  : "text-white"
              } px-6 py-2 hover:bg-slate-300`}
              onClick={() => navigate("/assignments")}
            >
              <div className="flex items-center">
                <MdAssignment className="mr-2"/>
                {!isCollapsed && "Assignments"}
              </div>
            </div>
          </li>
          {/* Manage Pricing */}
          <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "managePricing"
                  ? "text-white font-bold"
                  : "text-white"
              } px-6 py-2 hover:bg-slate-300`}
              onClick={() => navigate("/vehicles/fares")}
            >
              <div className="flex items-center">
                <FaWallet className="mr-2" />
                {!isCollapsed && "Manage Pricing"}
              </div>
            </div>
          </li>
          {/* Notification History*/}
          <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "NotificationHistory"
                 ? "text-white font-bold"
                  : "text-white"
              } px-6 py-2 hover:bg-slate-300`}
              onClick={() => navigate("/NotificationHistory")}
            >
              <div className="flex items-center">
                <FaBell className="mr-2" />
                {!isCollapsed && "Notification History"}
              </div>
            </div>
          </li>
          {/* Revenue Report */}
          <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "revenue"
                 ? "text-white font-bold"
                  : "text-white"
              } px-6 py-2 hover:bg-slate-300`}
              onClick={() => navigate("/revenuereport")}
            >
              <div className="flex items-center">
                <FaMoneyBill className="mr-2" />
                {!isCollapsed && " Revenue Report"}
              </div>
            </div>
          </li>
          {/* Rides */}
          <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "rides"
                ? "text-white font-bold"
                  : "text-white"
              } px-6 py-2 hover:bg-slate-300`}
              onClick={() => toggleSublist("rides")}
            >
              
              <div className="flex items-center">
                <FaBook className="mr-2" />
                {!isCollapsed && "Rides"}
              </div>
              {!isCollapsed &&(
               <div
               className="cursor-pointer p-0.25 rounded-full border border-white flex items-center justify-center text-xs"
             >
              
              {expanded.rides ? <FaChevronUp /> : <FaChevronDown />}
            </div>
              )}
  
  </div>
            {expanded.rides && !isCollapsed &&(
              <ul className="bg-customBlue text-white">
                <li className="w-full hover:bg-slate-300">
                  <a
                    href="/rides/add"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "addRide"
                        ? "text-white font-bold"
                  : "text-white"
                    }`}
                  >
                    Add Ride
                  </a>
                </li>
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/rides/all"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "AllRides"
                       ? "text-white font-bold"
                  : "text-white"
                    }`}
                  >
                    All Rides
                  </a>
                </li>
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/manage-scheduled-rides"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "mngScheduledRides"
                       ? "text-white font-bold"
                  : "text-white"
                    }`}
                  >
                    Manage Scheduled Rides
                  </a>
                </li>

              </ul>
            )}
          </li>
          {/* Coupons */}
          {/* <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "coupons"
                  ? "text-green-600 font-bold"
                  : "text-gray-600"
              } px-6 py-2 hover:bg-gray-200`}
              onClick={() => toggleSublist("coupons")}
            >
              <div className="flex items-center">
                <FaCar className="mr-2" />
                Coupons
              </div>
              {expanded.coupons ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {expanded.coupons && (
              <ul className="bg-gray-200 text-gray-600">
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/coupons/add"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "addCoupon"
                        ? "text-green-600 font-bold"
                        : "text-gray-600"
                    }`}
                  >
                    Add Coupon
                  </a>
                </li>
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/coupons/all"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "allCoupons"
                        ? "text-green-600 font-bold"
                        : "text-gray-600"
                    }`}
                  >
                    All Coupons
                  </a>
                </li>
              </ul>
            )}
          </li> */}
          {/* Cash Handling */}
          {/* <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "cashHandling"
                  ? "text-green-600 font-bold"
                  : "text-gray-600"
              } px-6 py-2`}
              onClick={() => navigate("/cashhandling")}
            >
              <div className="flex items-center">
                <ImCoinDollar className="mr-2" />
                Cash Handling
              </div>
            </div>
          </li> */}
          {/* Vehicles */}
          <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "vehicles"
                 ? "text-white font-bold"
                  : "text-white"
              } px-6 py-2 hover:bg-gray-200`}
              onClick={() => toggleSublist("vehicles")}
            >
              <div className="flex items-center">
                <FaCar className="mr-2" />
                {!isCollapsed &&"Vehicles"}
              </div>
              {!isCollapsed && (
                <div className="cursor-pointer p-0.25 rounded-full border border-white flex items-center justify-center text-xs">
              {expanded.vehicles ? <FaChevronUp /> : <FaChevronDown />}
            </div>
              )}
              </div>
            {expanded.vehicles && !isCollapsed &&(
              <ul className="bg-customBlue text-white">
                <li className="w-full hover:bg-slate-300">
                  <a
                    href="/vehicles/all"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "allVehicles"
                       ? "text-white font-bold"
                  : "text-white"
                    }`}
                  >
                    All Vehicles
                  </a>
                </li>
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/vehicles/brand"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "brand"
                        ? "text-white font-bold"
                  : "text-white"
                    }`}
                  >
                    Brand
                  </a>
                </li>
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/vehicles/model"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "model"
                        ? "text-white font-bold"
                  : "text-white"
                    }`}
                  >
                    Model
                  </a>
                </li>
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/vehicles/vehiclecategory"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "vehiclecategory"
                       ? "text-white font-bold"
                  : "text-white"
                    }`}
                  >
                    Vehicle Category
                  </a>
                </li>
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/vehicles/colors"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "color"
                        ? "text-white font-bold"
                  : "text-white"
                    }`}
                  >
                    Color
                  </a>
                </li>
              </ul>
            )}
          </li>
          {/* Security */}
          {/* <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "security"
                  ? "text-green-600 font-bold"
                  : "text-gray-600"
              } px-6 py-2 hover:bg-gray-200`}
              onClick={() => toggleSublist("security")}
            >
              <div className="flex items-center">
                <MdOutlineSecurity className="mr-2" />
                Security
              </div>
              {expanded.security ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {expanded.security && (
              <ul className="bg-gray-200 text-gray-600">
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/security/capabilities"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "capabilities"
                        ? "text-green-600 font-bold"
                        : "text-gray-600"
                    }`}
                  >
                    Capabilities
                  </a>
                </li>
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/security/roles"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "roles"
                        ? "text-green-600 font-bold"
                        : "text-gray-600"
                    }`}
                  >
                    Roles
                  </a>
                </li>
              </ul>
            )}
          </li> */}
          {/* ***"Page" Main Menu *** */}
         {/* < li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "page"
                  ? "text-green-600 font-bold"
                  : "text-gray-600"
              } px-6 py-2 hover:bg-gray-200`}
              onClick={() => toggleSublist("page")}
            >
              <div className="flex items-center">
                <FaFileAlt className="mr-2" />
                Page
              </div>
              {expanded.page ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {expanded.page && (
              <ul className="bg-gray-200 text-gray-600">
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/pages"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "allPages"
                        ? "text-green-600"
                        : "text-gray-600"
                    }`}
                    onClick={() => {
                      navigate("/pages/all");
                    }}
                  >
                    All Pages
                  </a>
                </li>
              </ul>
            )}
          </li> */}
          {/* Settings */}
          {/* <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "settingsform"
                  ? "text-green-600 font-bold"
                  : "text-gray-600"
              } px-6 py-2 hover:bg-gray-200`}
              onClick={() => navigate("/SettingsForm")}
            >
              <div className="flex items-center">
                <FaCog className="mr-2" />
                Settings
              </div>
            </div>
          </li> */}
        </ul>
      
      </div>
      
      

      {/* Overlay for mobile when sidebar is open */}
      |
    </>
  );
}

export default Sidebar;
