// src/Components/StatusBadge.jsx

import React from "react";
import PropTypes from "prop-types";

function StatusBadge({ status }) {
  if(status===false){
    status = "Disabled"
  }else{
    status="Enabled"
  }
  const statusStyles = {
    active: "bg-green-100 text-green-700",
    draft:"bg-green-100 text-green-700",
    published:"bg-green-100 text-green-700",
    inactive: "bg-red-100 text-red-700",
    enabled: "bg-green-100 text-green-800",
    disabled: "bg-red-100 text-red-800",
    pending: "bg-yellow-100 text-yellow-800",
    error: "bg-red-100 text-red-800",
  };

  return (
    <span
      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
        statusStyles[status.toLowerCase()] || "bg-gray-100 text-gray-700"
      }`}
    >
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </span>
  );
}

StatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusBadge;
