import React from "react";

function RescheduledRidesTable({ rides, onAccept, onDecline }) {
  return (
    <div className="w-full overflow-x-auto bg-white p-4 rounded shadow">
      {rides.length === 0 ? (
        <p className="text-center text-gray-500">No rescheduled rides available.</p>
      ) : (
        <table className="w-full text-left border-collapse rounded bg-white" cellSpacing="0">
          <thead>
            <tr className="border-b border-slate-300">
              <th
                scope="col"
                className="h-12 px-6 text-sm font-medium text-slate-700"
              >
                Ride ID
              </th>
              <th
                scope="col"
                className="h-12 px-6 text-sm font-medium text-slate-700"
              >
                Pickup
              </th>
              <th
                scope="col"
                className="h-12 px-6 text-sm font-medium text-slate-700"
              >
                Drop
              </th>
              <th
                scope="col"
                className="h-12 px-6 text-sm font-medium text-slate-700"
              >
                Driver
              </th>
              <th
                scope="col"
                className="h-12 px-6 text-sm font-medium text-slate-700"
              >
                Previous Scheduled Date
              </th>
              <th
                scope="col"
                className="h-12 px-6 text-sm font-medium text-slate-700"
              >
                Scheduled At
              </th>
            
              <th
                scope="col"
                className="h-12 px-6 text-sm font-medium text-slate-700"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {rides.map((ride) => (
              <tr key={ride.id} className="border-b border-slate-200">
                <td className="h-12 px-6 text-sm text-slate-500">
                  {ride.id || "N/A"}
                </td>
                <td className="h-12 px-6 text-sm text-slate-500">
                  {ride.pickup || "N/A"}
                </td>
                <td className="h-12 px-6 text-sm text-slate-500">
                  {ride.drop || "N/A"}
                </td>
                <td className="h-12 px-6 text-sm text-slate-500">
                  {ride.driverID || "N/A"}
                </td>
                <td className="h-12 px-6 text-sm text-slate-500">
                  {ride.scheduledDateTime
                    ? new Date(ride.oldDateTime).toLocaleString()
                    : "N/A"}
                </td>
                <td className="h-12 px-6 text-sm text-slate-500">
                  {ride.scheduledDateTime
                    ? new Date(ride.scheduledDateTime).toLocaleString()
                    : "N/A"}
                </td>
                <td className="h-12 px-6 py-5 text-sm text-slate-500">
                  <button
                    className="bg-green-500 text-white px-3 py-1 mb-3 rounded hover:bg-green-600 transition duration-200 mr-2"
                    onClick={() => onAccept(ride.id)}
                  >
                    Accept
                  </button>
                  <button
                    className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition duration-200"
                    onClick={() => onDecline(ride.id)}
                  >
                    Decline
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default RescheduledRidesTable;
