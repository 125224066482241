// http://localhost:3000/api/adminpanel/getridebustatus/:pageNumber/:itemsPerPage'

import { ACCEPTCANCEL_RESCHEDULED, ADMIN_ROUTE, ASSIGN_DRIVER_SCHEDULED, BASE_URL, CANCEL_RIDE, COMPLETE_TRIP, GET_ALL_SCHEDULED_RIDES, GET_DRIVER_FOR_SCHEDULED, GET_RIDES, REASSIGN_DRIVER_SHEDULED } from "./apiEndPoint";
import { apiPostFetcher, apiGetFetcher, apiPutFetcher } from "./apiFetcher";


const adminRoute = `${BASE_URL}${ADMIN_ROUTE}`

/**
 * To get all brands also supports search
 * @param {Number} page - Page to be retreived
 * @param {Number} items -  Items per page
 * @param {Object} payload - Search by brand name and status.
 * @param {String} token - Bearer Token.
 */
const getRidesAPI = async (page, items, payload, token) => {
  let url = `${adminRoute}${GET_RIDES}/${page}/${items}`;
  return apiPostFetcher(url, payload, token);
};

/**
* To get all the scheduled rides in the system.
* @param {String} token - Bearer Token.
*/
const getScheduledRidesAPI = async (token) => {
  let url = `${adminRoute}${GET_ALL_SCHEDULED_RIDES}`;
  return apiGetFetcher(url, token);
};

/**
* To get schedule of the driver with the given vehicle model.
* @param {String} payload - Vehicle model.
* @param {String} token - Bearer Token.
*/
const getDriverScheduleAPI = async (payload, token) => {
  let url = `${adminRoute}${GET_DRIVER_FOR_SCHEDULED}`;
  return apiPostFetcher(url, payload, token);
};

/**
* To get all the scheduled rides in the system.
* @param {String} token - Bearer Token.
* @param {Object} payload - Driver Id and Ride Id.
*/
const assignDriverScheduleAPI = async (payload, token) => {
  let url = `${adminRoute}${ASSIGN_DRIVER_SCHEDULED}`;
  return apiPutFetcher(url, payload, token);
};

/**

/**
* Reassign a driver for a scheduled ride.
* @param {Object} payload - Must include { rideId, newDriverId }
* @param {String} token - Bearer Token
* @returns {Promise} - The response from the server
*/
const reassignDriverScheduledAPI = async (payload, token) => {
  const url = `${adminRoute}${REASSIGN_DRIVER_SHEDULED}`;
  return apiPutFetcher(url, payload, token);
};

/**
* An API to approve or decline a rescheduled scheduled ride from the customer.
* @param
*/
const acceptCancelRescheduledRideAPI = async (payload, token) => {
  let url = `${adminRoute}${ACCEPTCANCEL_RESCHEDULED}`
  return apiPostFetcher(url, payload, token)
}

/**
 * Cancel an ongoing ride.
 * @param
 */
const cancelRideAPI = async (rideId, payload, token) => {
  let url = `${adminRoute}${CANCEL_RIDE}/${rideId}`
  return apiPostFetcher(url, payload, token)
}

/**
 * Complete a ride
 */
  const completeRideAPI = async(payload,token) => {
    let url = `${adminRoute}${COMPLETE_TRIP}`
    return apiPostFetcher(url,payload,token)
  }



const ridesAPI = {
  getRidesAPI,
  getScheduledRidesAPI,
  getDriverScheduleAPI,
  assignDriverScheduleAPI,
  cancelRideAPI,
  acceptCancelRescheduledRideAPI,
  reassignDriverScheduledAPI,
  completeRideAPI

}

export default ridesAPI