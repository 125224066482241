// src/Pages/Vehicles/CategoriesList.jsx

import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { MdEdit, MdDelete } from "react-icons/md";
import Pagination from "../../Components/Pagination";
import vehicleAPI from "../../utils/API/vehicleApis";
import { useNavigate } from "react-router-dom";
import useAuthToken from "../../hooks/customHooks";
import { toast } from "sonner"; // Import Sonner for toast notifications
import ConfirmModal from "../../Components/ConfirmModal"; // Import ConfirmModal

function CategoriesList() {
  const navigate = useNavigate();
  const token = useAuthToken();
  const [filterValue, setFilterValue] = useState({
    categoryName: ""
  });
  const [searchPayload, setSearchPayload] = useState({
    categoryName: ""
  });

  // State for pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // State for categories data, total pages, and total items
  const [categories, setCategories] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0); // Added totalItems

  // State for loading and error
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // State for confirmation modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  /**
   * Fetches categories from the API based on current filters and pagination.
   */
  const fetchCategories = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await vehicleAPI.getVehiclesCategoryAPI(
        currentPage,
        itemsPerPage,
        searchPayload,
        token
      );

      setCategories(response.data.paginatedData);
      setTotalPages(response.data.totalPages);
      setTotalItems(response.data.totalItems); // Set total items
    } catch (err) {
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "An error occurred while fetching categories.";
      setError(errorMessage);
      toast.error("Failed to fetch categories. Please try again."); // Show error toast
    } finally {
      setLoading(false);
    }
  };

  // Fetch data whenever currentPage, itemsPerPage, or searchPayload changes
  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, searchPayload]);

  /**
   * Handles the filter button click.
   */
  const handleFilter = () => {
    setSearchPayload(filterValue);
    setCurrentPage(1); // Reset to first page on new filter
  };

  /**
   * Handles changes in the search input.
   */
  const handleSearchChange = (e) => {
    setFilterValue((prev) => ({
      ...prev,
      searchQuery: e.target.value,
    }));
  };

  /**
   * Resets the filters to their initial state.
   */
  const handleReset = () => {
    setFilterValue({ searchQuery: "", status: "" });
    setSearchPayload({ searchQuery: "", status: "" });
    setCurrentPage(1);
  };

  /**
   * Handles the editing of a category.
   * @param {string} categoryId - The ID of the category to edit.
   * @param {object} category - The category object.
   */
  const handleEdit = (categoryId, category) => {
    navigate(`/vehicles/edit-vehicle-category/${categoryId}`, {
      state: { category },
    });
  };

  /**
   * Opens the confirmation modal to delete a category.
   * @param {object} category - The category object to delete.
   */
  const handleDeleteClick = (category) => {
    setCategoryToDelete(category);
    setIsModalOpen(true);
  };

  /**
   * Confirms the deletion of the category.
   */
  const confirmDeleteCategory = async () => {
    if (!categoryToDelete) return;

    setIsModalOpen(false);
    setLoading(true);
    setError(null);

    try {
      const result = await vehicleAPI.deleteVehicleCategoryAPI(
        categoryToDelete.id,
        currentPage,
        itemsPerPage,
        token
      );
      if (result.status !== 200) {    
        toast.error(result.data.error||result.data.message||"Category couldn't be deleted"); 
      } else if (result.status === 200) {
        toast.success("Category deleted successfully!"); 
      }

      await fetchCategories(); // Refetch data to update the list
    } catch (err) {
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "Failed to delete category.";
      setError(errorMessage);
      toast.error("Failed to delete category. Please try again."); // Show error toast
      // Revert to previous state in case of error
      setCategories((prevCategories) => [...prevCategories, categoryToDelete]);
      setTotalItems((prevTotal) => prevTotal + 1);
      setTotalPages(Math.ceil(totalItems / itemsPerPage));
    } finally {
      setLoading(false);
      setCategoryToDelete(null);
    }
  };

  /**
   * Cancels the deletion and closes the modal.
   */
  const cancelDelete = () => {
    setIsModalOpen(false);
    setCategoryToDelete(null);
  };

  /**
   * Handles change in items per page.
   * @param {object} e - Event object.
   */
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  /**
   * Handles page number click.
   * @param {number} pageNumber - The page number to navigate to.
   */
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  /**
   * Handles navigation to the first page.
   */
  const handleFirstPage = () => setCurrentPage(1);

  /**
   * Handles navigation to the last page.
   */
  const handleLastPage = () => setCurrentPage(totalPages);

  /**
   * Handles navigation to the previous page.
   */
  const handlePreviousPage = () =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));

  /**
   * Handles navigation to the next page.
   */
  const handleNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        {/* Sidebar with current selection */}
        <Sidebar
          currentSelection={{ main: "vehicles", sub: "vehiclecategory" }}
        />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto">
          <div className="container mx-auto p-4">
            {/* Heading */}
            <h1 className="text-2xl text-gray-600 font-bold mb-4">
              Vehicle Categories
            </h1>

            {/* Search and Filter Section */}
            <div className="bg-customBlue p-5 mb-8 rounded-xl">
              <div className="flex flex-col md:flex-row space-x-0 md:space-x-16 items-center">
                {/* Search Input */}
                <div className="flex flex-col text-white mb-4 md:mb-0">
                  <label htmlFor="search">Search</label>
                  <input
                    id="search"
                    type="text"
                    placeholder="Search by Category Name"
                    value={filterValue.searchQuery}
                    onChange={handleSearchChange}
                    className="border border-gray-300 rounded p-2 w-full md:w-96"
                  />
                </div>
              </div>

              {/* Filter and Reset Buttons */}
              <div className="flex gap-2 mt-4">
                <button
                  className="bg-green-700 text-white border border-green-700 rounded-lg p-2 h-10"
                  onClick={handleFilter}
                >
                  Filter
                </button>
                <button
                  className="bg-red-700 text-white border border-red-700 rounded-lg p-2 h-10"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </div>

            {/* Categories List Header */}
            <div className="flex bg-stone-50 font-semibold justify-between text-gray-600 mb-0.5 p-3 rounded-t-xl">
              <div>Categories List</div>
              {/* Items Per Page Selector */}
              <div className="flex items-center space-x-2">
                <label htmlFor="itemsPerPage" className="text-sm">
                  Items per page:
                </label>
                <select
                  id="itemsPerPage"
                  className="border border-gray-300 text-sm rounded p-2"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
                <button
                  className="border border-emerald-900 text-sm font-normal rounded-md p-2 text-emerald-900"
                  onClick={() => {
                    navigate("/vehicles/add-vehicle-category");
                  }}
                >
                  + Add New Category
                </button>
              </div>
            </div>

            {/* Pagination and Items Information */}
            <div className="flex flex-col md:flex-row bg-stone-50 justify-between items-center text-sm mb-0.5 p-3 rounded-b-none rounded-t-none">
              {/* Showing X to Y of Z */}
              <div className="mb-2 md:mb-0">
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span>
                    Showing{" "}
                    {totalItems === 0
                      ? 0
                      : (currentPage - 1) * itemsPerPage + 1}{" "}
                    to {Math.min(currentPage * itemsPerPage, totalItems)} of{" "}
                    {totalItems}
                  </span>
                )}
              </div>

              {/* Pagination Component */}
              {totalPages > 1 && (
                <div className="flex justify-center mt-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageClick}
                    onFirstPage={handleFirstPage}
                    onLastPage={handleLastPage}
                    onPreviousPage={handlePreviousPage}
                    onNextPage={handleNextPage}
                  />
                </div>
              )}
            </div>

            {/* Categories List Section */}
            <div className="space-y-0">
              {/* Header Row */}
              <div className="flex bg-stone-50 justify-center items-center font-bold text-sm mb-0.5 p-2 border-b border-gray-300">
                <div className="flex-1 text-left">ID</div>
                <div className="flex-1 text-left">Category</div>
                <div className="flex-1 text-left">Created At</div>
                <div className="flex-1 text-right">Actions</div>
              </div>

              {/* Loading and Error States */}
              {loading ? (
                <div className="flex justify-center p-4 text-gray-500">
                  Loading...
                </div>
              ) : error ? (
                <div className="flex bg-white p-4 text-center text-gray-500">
                  {error}
                </div>
              ) : categories?.length > 0 ? (
                categories.map((category) => (
                  <div
                    key={category.id} // Ensure category.id is unique and exists
                    className="flex bg-white p-2 border-b border-gray-300 transition-transform transform text-sm gap-8 hover:bg-gray-50"
                  >
                    <div className="flex-1 px-1 text-left">{category.id}</div>
                    <div
                      className="flex-1 overflow-hidden text-left"
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}
                    >
                      {category.category_name}
                    </div>
                    <div className="flex-1 mr-4 text-left">
                      {new Date(category.createdAt).toLocaleDateString()}{" "}
                      {/* Format date */}
                    </div>

                    <div className="flex-1 flex justify-end space-x-2">
                      <button
                        className="bg-transparent text-green-700 border border-green-700 rounded p-2 text-xs h-8 hover:bg-green-100"
                        aria-label={`Edit category ${category.id}`}
                        onClick={() => handleEdit(category.id, category)}
                      >
                        <MdEdit />
                      </button>
                      <button
                        className="bg-transparent text-red-700 border border-red-700 rounded p-2 text-xs h-8 hover:bg-red-100"
                        aria-label={`Delete category ${category.id}`}
                        onClick={() => handleDeleteClick(category)} // Attach delete handler
                        disabled={loading} // Disable while loading
                      >
                        <MdDelete />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex bg-white p-4 text-center text-gray-500">
                  No categories found.
                </div>
              )}

              {/* Footer Showing X to Y of Z */}
              <div className="flex flex-col md:flex-row bg-stone-50 justify-between items-center text-sm mb-0.5 p-3 rounded-b-xl">
                <div>
                  Showing{" "}
                  {totalItems === 0 ? 0 : (currentPage - 1) * itemsPerPage + 1}{" "}
                  to {Math.min(currentPage * itemsPerPage, totalItems)} of{" "}
                  {totalItems}
                </div>

                {/* Pagination Component at Bottom */}
                {totalPages > 1 && (
                  <div className="flex justify-center mt-4">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageClick}
                      onFirstPage={handleFirstPage}
                      onLastPage={handleLastPage}
                      onPreviousPage={handlePreviousPage}
                      onNextPage={handleNextPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Modal for Deletion */}
      <ConfirmModal
        isOpen={isModalOpen}
        title="Confirm Deletion"
        message={`Are you sure you want to delete the category "${categoryToDelete?.category_name}"? This action cannot be undone.`}
        onConfirm={confirmDeleteCategory}
        onCancel={cancelDelete}
      />

      {/* Remove the fixed error display and rely on toasts */}
      {/* {error && (
        <div className="fixed bottom-4 right-4 bg-red-500 text-white p-3 rounded">
          {error}
        </div>
      )} */}
    </>
  );
}

export default CategoriesList;
